import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { saveAddress, editAddress, getAddress, convertToEth } from "../../api/wallets";
import { Container, Card, ButtonGroup, Row, Modal, Form, Button, ProgressBar } from "react-bootstrap";
import "../../styles/Checkout.css";
import Select from "react-select";
import { getCoupon, saveTransaction } from "../../api/wallets";
import moment from 'moment';
import { Countries, States, Cities } from 'countries-states-cities-service'
import getSymbolFromCurrency from 'currency-symbol-map'
import { estimateCost, submitOrder } from '../../api/products'
import { useContract, Web3Button, useAddress, useWallet } from "@thirdweb-dev/react";
import { ethers } from "ethers";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkIfAllowedToPurchase } from '../../api/orders'
import { getOwnedNft } from "../../api/nfts";



import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { sassFalse } from "sass";



const client_id = process.env.REACT_APP_CLIENT_ID;
const app_secret = process.env.REACT_APP_APP_SECRET;




function Checkout({ currency, currencyRates, cart, setCart, walletId, coupon, handleUserChange, handleCouponClick, setShowCartModal, tokenIds }: any) {
	const navigate = useNavigate();
	const address_wallet = useAddress();
	const connected = useWallet();
	const [state, setState] = useState<any>({
		country: "",
		region: ""
	})


	const { contract: nftCollection } = useContract(
		process.env.REACT_APP_PUBLIC_NFT_COLLECTION_ADDRESS
	);

	const [stepOne, setStepOne] = useState(true);
	const [stepTwo, setStepTwo] = useState(false);
	const [stepThree, setStepThree] = useState(false);
	const [edit, setEdit] = useState(false);
	const [add, setAdd] = useState(false);
	const [line_items, setLineItems] = useState<any[]>([]);
	const [selectedAddress, setSelectedAddress] = useState<any>({});
	const [purchaseItems, setItems] = useState<any>({});
	const [addresses, setAddresses] = useState<any>([]);
	const [addressId, setAddressId] = useState();
	const [countries, setCountries] = useState<any>();
	const [regions, setRegions] = useState<any[]>([]);
	const [selectedCountry, setSelectedCountry] = useState<any | null>(null);
	const [selectedRegion, setSelectedRegion] = useState<any | null>(null);
	const [address_to, setAddress] = useState({});
	const [couponCode, setCouponCode] = useState('');
	const [iso2, setIso2] = useState('');
	const [isClickable, setClickable] = useState(true);
	const [transaction, setTransaction] = useState<any>();
	const [defaultAddress, setDefaultAddress] = useState({});

	const [shippingCost, setShippingCost] = useState(0);
	const [shippingLoad, setShippingLoad] = useState(true);
	const [showProgressModal, setShowProgressModal] = useState(false);
	const [progress, setProgress] = useState(0);

	const [showPaypal, setShowPaypal] = useState(false);
	const [showEth, setShowEth] = useState(false);
	const [success, setSuccess] = useState(false);
	const [ErrorMessage, setErrorMessage] = useState("");
	const [orderID, setOrderID] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [countryList, setCountryList] = useState<any>();
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [errorMsgTitle, setErrorMsgTitle] = useState('');
	const [removeMsg, setRemoveMsg] = useState('');
	const [removeType, setRemoveType] = useState('');
	const [allowedToPurchase, setAllowedToPurchase] = useState<Boolean>();

	const [showErrorPerm, setShowErrorPerm] = useState(false);


	const [ethRate, setEthRate] = useState(0);
	// const address = useAddress();
	// const data = useBalance(address);

	// const [phoneNumber, setPhoneNumber] = useState('');
	const [phoneNumberError, setPhoneNumberError] = useState(false);
	const [numberError, setNumberError] = useState(false);
	const phoneNumberPattern = new RegExp('^[0-9+ -]{6,}$');
	// const postalCode = new RegExp('^\d{4,}$');

	// const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/;
	const validatePhoneNumber = (number: any) => {
		if (phoneNumberPattern.test(number)) {
			// Phone number is valid
			setPhoneNumberError(false);
			return false;
			// Proceed with further actions
		} else {
			// Phone number is invalid
			setPhoneNumberError(true);
			return true;
		}


	};

	function isValidPostal(variable: any): boolean {
		const postalCodeRegex = /^\d{4,}$/;
		// const x = Number(variable);
		if (postalCodeRegex.test(variable)) {
			// Phone number is valid
			setNumberError(false);
			return true;
			// Proceed with further actions
		} else {
			// Phone number is invalid
			setNumberError(true);
			return false;
		}


	}







	// const countryList = useLoaderData();




	const calculateTotalPrice = (cartItems: any) => {
		let sum = 0;
		cartItems.forEach((product: any) => {
			sum += product.quantity * product.price;
		});
		return sum;
	};

	const getShippingAddress = async (id: any) => {
		const res = await getAddress(id);
		// console.log('res', res);




		if (res.length != 0) {
			setStepTwo(false);
			setAddressId(res[0].id)
		}
		// setDefaultAddress(res[0])


		setAddresses(res);
		selectShippingAddress(res[0])



	};

	function priceConversion(price: any) {
		// const currencyRate = rates.filter((r:any) => r.value === currency.value)
		// const fromCent = parseFloat(price) / 100;
		const newPrice = price * currency.rate;
		// const truncatedNumber = Math.floor(newPrice * 100) / 100;
		return newPrice.toFixed(2);
	}

	const calculateTotalPriceConvertion = (cartItems: any) => {
		let sum = 0;
		cartItems.forEach((product: any) => {
			const newPrice = product.price * currency.rate;
			sum += parseFloat(newPrice.toFixed(2)) * product.quantity;
		});
		// const truncatedNumber = Math.floor(sum * 100) / 100;
		// console.log('newprice', truncatedNumber)
		// const final = sum ;
		return sum.toFixed(2);
	}
	const calculateCoupon = (amount: any) => {
		const item_total = calculateTotalPriceConvertion(cart);
		const couponAmountRate = currencyRates.find((curr: any) => curr.value === coupon.currency)
		const couponAmountConversion = (currency.rate / couponAmountRate?.rate)
		const newCouponAmount = coupon.amount * couponAmountConversion;
		// setNewCouponAmount(newCouponAmount);

		// console.log('newCouponAmount', newCouponAmount)

		const excludeShipping = parseFloat(item_total) - newCouponAmount;
		let final = (isNaN(newCouponAmount) ? '0.00' : newCouponAmount.toFixed(2))
		// console.log('excludeShipping', excludeShipping);
		if (excludeShipping < 0) {
			final = parseFloat(item_total).toFixed(2);
		}
		// console.log('current', final)


		// console.log('shipping_cost', shipping_cost);
		return final;
	}


	const calculateTotalDiscountPriceConvertion = () => {

		const item_total = calculateTotalPriceConvertion(cart);
		const shipping_cost = priceConversion(shippingCost / 100);

		// console.log('cart', cart)

		const discount = coupon.amount;

		const dis = (isNaN(parseFloat(discount)) ? 0.00 : parseFloat(discount))
		// console.log('discount', dis)
		const excludeShipping = parseFloat(item_total) - dis;
		// console.log('excludeShipping', excludeShipping);
		// console.log('shipping_cost', shipping_cost);
		let total = parseFloat(shipping_cost);
		if (excludeShipping > 0) {
			total += excludeShipping;
		}

		const final = (isNaN(total) ? 0.00 : total);


		return final;
	}

	useEffect(() => {

		const items = cart.map((item: any) => ({
			name: item.title,
			description: item.variant_title,
			sku: item.variant_sku,
			quantity: item.quantity.toString(),
			unit_amount: {
				currency_code: currency.value,
				value: priceConversion(item.price)
			}
		}));


		const purchase_units = {
			purchase_units: [

				{
					items: items,
					amount: {
						currency_code: currency.value,
						value: calculateTotalDiscountPriceConvertion().toFixed(2),
						breakdown: {
							item_total: {
								currency_code: currency.value,
								value: calculateTotalPriceConvertion(cart)
							},
							shipping: {
								currency_code: currency.value,
								value: priceConversion(shippingCost / 100)
							},
							discount: {
								currency_code: currency.value,
								value: calculateCoupon(coupon.amount)
							},
						}
					},
					shipping: {
						type: "SHIPPING",
						name: {
							full_name: selectedAddress.firstname + " " + selectedAddress.lastname
						},
						address: {
							address_line_1: selectedAddress.address,
							address_line_2: "",
							admin_area_2: selectedAddress.city,
							admin_area_1: selectedAddress.country,
							postal_code: selectedAddress?.postal_code?.toString(),
							country_code: iso2
						}
					},
				}
			]
		}


		// console.log('puuu', purchase_units);



		setItems(purchase_units);

	}, [shippingCost, coupon.amount]);

	useEffect(() => {
		// console.log('data', address);
		setIsLoading(true);
		if (countries && walletId) {
			setLineItems(
				cart.map(({ variant_sku: sku, quantity }: any) => ({ sku, quantity }))
			);

			// console.log('wallet', walletId)
			getShippingAddress(walletId);
			setIsLoading(false);
		}
	}, [countries, cart, walletId]);

	


	useEffect(() => {
		// console.log('token', tokenId)

		// setCountries(countryList);
		// const states = States.getStates({ filters: { country_code: 'SG' } });



		handleCouponClick({})
		try {
			const response = Countries.getCountries();

			const countryOptions = response?.map((country: any) => ({
				value: country.iso2,
				label: country.name,
				phone: country.phone_code,
				regions: States.getStates({ filters: { country_code: country.iso2 } }),
			}));
			// getShippingAddress(walletId);

			setCountries(countryOptions);
			// return response;

		} catch (error) {
			// return error;
			console.error('Error fetching countries:', error);
		}

		const getEthRate = async () => {

			const res = await convertToEth();
			setEthRate(res.ETH);

		}

		getEthRate();


		setLineItems(
			cart.map(({ variant_sku: sku, quantity }: any) => ({ sku, quantity }))
		);

		// handleCouponClick({})
		// console.log('setAllowedToPurchase3', tokenId)
		// if (tokenId != undefined) {
		// 	console.log('setAllowedToPurchase1', tokenId)
		// 	handleCheckWalletToPurchase()
		// }





	}, []);



	const handleCheckWalletToPurchase = async () => {
		setErrorMsg('');
		
		let allowed: any = {};
		if (tokenIds != 'empty') {
			allowed = await checkIfAllowedToPurchase(tokenIds[0].metadata.id, address_wallet);
		} else {
			allowed = await checkIfAllowedToPurchase(undefined, address_wallet);
		}


		// if (tokenId != undefined || tokenId == null) {
		if (allowed.error) {
			// navigate('/');
			setAllowedToPurchase(false);
			setErrorMsgTitle(allowed.msg_title);
			setErrorMsg(allowed.msg);
			// setIsLoading(false);
			setShowErrorPerm(true);
			// console.log('1')
			// mintNft();
		} else {
			// console.log('2')
			setShowErrorPerm(false);
			setAllowedToPurchase(true)

			// setErrorMsgTitle(allowed.msg_title);
			// setErrorMsg(allowed.msg);
			// setIsLoading(false);

		}
		// }

	};

	useEffect(() => {
		
		// const newPerm = !allowedToPurchase;
		// setAllowedToPurchase(newPerm);
		if (address_wallet) {
			// console.log('6', tokenIds)
			if (tokenIds != undefined) {
				// console.log('setAllowedToPurchase2', tokenIds[0])
				handleCheckWalletToPurchase()

			}

		}

		// console.log('allowed', allowedToPurchase)

	}, [address_wallet, tokenIds]);


	const handleCountryChange = (selectedOption: any) => {
		// console.log('regcccc', selectedOption)
		// setState({ country: selectedOption });
		setSelectedCountry(selectedOption);
		setSelectedRegion(null);

		if (selectedOption) {
			const countryCode = selectedOption.value;
			const selectedCountryData = countries.find((country: any) => country.value === countryCode);

			// console.log('selectedCountryData', selectedCountryData)
			if (selectedCountryData) {
				const states = States.getStates({ filters: { country_code: selectedCountryData.value } });

				setRegions(states.map((region: any) => ({ value: region.state_code, label: region.name })));
			} else {
				setRegions([]);
			}
			setIso2(selectedCountryData.value);
			setSelectedAddress({ ...selectedAddress, country: selectedOption.label });
		} else {
			setRegions([]);
		}
	};

	const handleRegionChange = (selectedOption: any) => {
		setSelectedAddress({ ...selectedAddress, region: selectedOption.label });

		// const cities = Cities.getCities({
		// 	filters: {
		// 	  country_code: 'IT',
		// 	},
		//   })

		// console.log('reg', selectedRegion)
		// setSelectedAddress({
		// 	...selectedAddress,
		// 	postal_code: e.target.value,
		// })
		setSelectedRegion(selectedOption);
	};

	const estimateShipping = async (address: any, iso2: any) => {


		// console.log('cart', cart);

		const add = {
			first_name: address.firstname,
			last_name: address.lastname,
			email: address.email,
			phone: address.phone,
			country: iso2,
			region: selectedRegion?.value,
			address1: address.address,
			address2: "",
			city: address.city,
			zip: address.postal_code.toString(),
		};

		handleUserChange(add);

		const body = {
			line_items,
			"address_to": add,
		};
		// console.log('line_items22', line_items)
		try {
			const data = await estimateCost(body);
			// console.log('estimateShipping', data)
			setShippingCost(data.standard);
			setShippingLoad(false);
		} catch (error) {
			console.error('Error estimating shipping cost :', error);
		}

	}



	const saveShippingAddress = async (e: any) => {

		e.preventDefault();
		setPhoneNumberError(false);
		setNumberError(false);
		const isPhoneInvalid = validatePhoneNumber(selectedAddress.phone);
		const isPostalValid = isValidPostal(selectedAddress.postal_code)
		// console.log('isPhoneInvalid', isPhoneInvalid)
		// console.log('isPostalValid', isPostalValid)

		if (!isPhoneInvalid && isPostalValid) {
			setStepOne(false);
			setStepTwo(true);
			setStepThree(false);
			setAdd(false);
			// console.log("save", selectedAddress);

			const res = await saveAddress(selectedAddress, walletId);
			// console.log('see', res)
			getShippingAddress(walletId);
			
		}
		// estimateShipping();

		// setAddressId(res.id);

		// console.log("saveShippingAddress", res);
	};

	const editShippingAddress = async (e: any) => {

		e.preventDefault();
		setPhoneNumberError(false);
		setNumberError(false);
		const isPhoneInvalid = validatePhoneNumber(selectedAddress.phone);
		const isPostalValid = isValidPostal(selectedAddress.postal_code)
		// console.log('isPhoneValid', isPhoneValid)
		if (!isPhoneInvalid && isPostalValid) {
			setStepOne(false);
			setStepTwo(false);
			setStepThree(false);
			setEdit(false);
			const res = await editAddress(selectedAddress, addressId);
			getShippingAddress(walletId);
			setAddressId(res);
		}
		// estimateShipping();


		// console.log("res", res);
	};

	const editAddressForm = async (address: any) => {
		setSelectedAddress(address);

		setAddressId(address.id);
		const updateCountry = await countries.filter((country: any) =>
			country.label === address.country
		);

		// console.log('updateCountry', updateCountry);

		if (updateCountry) {
			// const states = States.getStates({ filters: { country_code: updateCountry.value } });
			// console.log('tsate', updateCountry.regions);
			const states = updateCountry[0].regions;

			setRegions(states.map((region: any) => ({ value: region.state_code, label: region.name })));
		}

		const updateRegion = await updateCountry[0].regions.filter((region: any) =>
			// console.log('sss', country)
			region.name === address.region
		);

		// console.log('filteredCats', filteredCats)
		// const reg = coun.regions.filter((opt:any) => opt.label === selectedAddress.city);
		setSelectedCountry(updateCountry);
		setSelectedRegion({ value: updateRegion[0].state_code, label: updateRegion[0].name });
		// estimateShipping();
		setStepOne(false);
		setStepTwo(true);
		setStepThree(false);
		setEdit(true);
		setAdd(false);
		// const coun = countries.filter((opt:any) => opt.label === );

	};

	const addAddressForm = async (id: any) => {
		// estimateShipping();
		setPhoneNumberError(false);
		setNumberError(false);
		setSelectedAddress({});
		setSelectedCountry({});
		setSelectedRegion({});
		setStepOne(false);
		setStepTwo(false);
		setStepThree(false);
		setEdit(false);
		setAdd(true);

	};

	function savePayment() {
		// setClickable(false);
		setStepOne(false);
		setStepTwo(false);
		setStepThree(true);
	}

	function continueToShip() {
		// setClickable(false);
		setStepOne(false);
		setStepTwo(true);
		setStepThree(false);
	}

	function saveOrder() {
		setStepOne(false);
		setStepTwo(false);
		setStepThree(true);
	}

	function cancelOrder() {
		if (stepThree) {
			setClickable(true);
			setStepOne(false);
			setStepTwo(false);
			setStepThree(false);
		}

	}

	const selectShippingAddress = async (address: any) => {

		if (isClickable && address) {
			setShippingLoad(true);
			setSelectedAddress(address);
			setAddressId(address.id);
			const selectedCountry = countries.filter((country: any) =>
				country.label === address.country);


			const selectedRegion = await selectedCountry[0].regions.filter((region: any) =>
				// console.log('sss', country)
				region.name === address.region
			);
			setIso2(selectedCountry[0].value);
			setSelectedCountry(selectedCountry);
			setSelectedRegion({ value: selectedRegion[0].state_code, label: selectedRegion[0].name });




			// console.log('address_to', address_to)
			estimateShipping(address, selectedCountry[0].value);


		}

		// console.log("id", address.id);

	};

	function getCouponDiscount(e: any) {

		e.preventDefault();
		// console.log('this happens', tokenIds)
		let idsArray: any;
		if (tokenIds != 'empty') {
			idsArray = tokenIds.map((item: any) => item.metadata.id);
		}

		const getDiscount = async () => {
			const tokens = idsArray != undefined ? idsArray : '';
			// console.log('tokens', tokens);

			const res = await getCoupon(couponCode, tokens, address_wallet);
			// console.log('res', res);
			if (!res.error) {
				handleCouponClick(res.data)
				setShowModal(false);
			} else {
				setErrorMsgTitle(res.msg_title);
				setErrorMsg(res.msg);
				setShowErrorModal(true);
			}
			// console.log('res', res)
			// 


		};

		getDiscount();
	}





	// function getPurchse() {

	// 	console.log('purch', JSON.stringify(purchase_units))
	// }



	// creates a paypal order
	const createOrder = (data: any, actions: any) => {

		// console.log('purchaseItems', purchaseItems)
		return actions.order.create(purchaseItems).then((orderID: any) => {
			// console.log('id', orderID);
			setOrderID(orderID);
			return orderID;
		});
	};

	// check Approval
	const onApprove = async (data: any, actions: any) => {
		return await actions.order.capture().then(function (details: any) {
			const { payer } = details;
			// console.log('uddd', details)
			setShowProgressModal(true);
			placeOrder(details, 'paypal');

			setTransaction(details);


			// alert('payment successful')
			// setShowModal(true);
			// setSuccess(true);
		});


	};

	const placeOrder = async (trans: any, payment_type: any) => {



		// console.log('selecedte', selectedIso2[0].value);

		const add = {
			first_name: selectedAddress.firstname,
			last_name: selectedAddress.lastname,
			email: selectedAddress.email,
			phone: selectedAddress.phone,
			country: iso2,
			region: selectedRegion.value,
			address1: selectedAddress.address,
			address2: "",
			city: selectedAddress.city,
			zip: selectedAddress.postal_code.toString(),
		};

		const body = {
			"external_id": "",
			line_items,
			"shipping_method": 1,
			"address_to": add,
		};

		if (payment_type == 'cryptocurrency') {
			body.external_id = "Nokko-" + trans.blockNumber + '-' + trans.transactionIndex;
		} else {
			body.external_id = "Nokko-" + trans.purchase_units[0].payments.captures[0].id;
		}
		// console.log('bodybody', body)

		try {
			const data = await submitOrder(body);
			// const data = {
			// 	id: '64cbf7cf67fec8d42c06ba85'
			// }
			saveTransactionOrder(data, trans, payment_type)
			// console.log('bodybody', body)
		} catch (error) {
			console.error('Error submitting order:', error);
		}


	}

	const saveTransactionOrder = async (data: any, trans: any, payment_type: any) => {

		// console.log('HVSDOFYWVDEFOUYW', data)


		const cartItems = cart.map((item: any) => ({
			...item,
			cost: parseFloat(priceConversion(item.price)) * item.quantity
		}));
		let idsArray: any;
		let ids: any;
		if (tokenIds != 'empty') {
			ids = tokenIds.map((item: any) => item.metadata.id);
			// const tokens = idsArray != undefined ? idsArray : '';
			// console.log('idsss', ids)

		}
		let tokens : any ;
		if (ids) {
			tokens = ids && ids.length > 1 ? ids.join(', ') : ids[0];
		} else {
			tokens = '';
		}
		 
		// console.log('iddsss', tokens);
		const order_data = {
			"nft_token_ids": tokens,
			"address_id": addressId,
			"wallet_id": walletId,
			"total_amount": calculateTotalDiscountPriceConvertion(),
			"coupon_code": coupon.code,
			"coupon_code_amount": calculateCoupon(coupon.amount),
			"payment_transaction_id": '',
			"payment_source": "paypal",
			"printify_order_id": data.id,
			"currency": currency.value,
			"currency_symbol": getSymbolFromCurrency(currency.value),
			"item_details": cartItems,
			"shipping_amount": priceConversion(shippingCost / 100),
			"subtotal": calculateTotalPriceConvertion(cart),

		};



		if (payment_type == 'cryptocurrency') {
			order_data.payment_source = 'cryptocurrency';
			order_data.total_amount = Number(getCurrency());
			order_data.coupon_code_amount = (Number(calculateCoupon(coupon.amount)) * ethRate).toFixed(4);
			order_data.payment_transaction_id = trans.blockNumber + '-' + trans.transactionIndex;
			order_data.currency = 'ETH';
			order_data.currency_symbol = 'ETH';

		} else {
			order_data.payment_transaction_id = trans.purchase_units[0].payments.captures[0].id;
		}
		// console.log('order_data', order_data);

		// console.log("saveTransactionOrder", JSON.stringify(order_data))
		const res = await saveTransaction(order_data);
		// console.log('resss', res)
		setCart([]);
		handleCouponClick({ amount: 0 })
		setShowProgressModal(false);
		navigate('/thank-you/' + data.id);


		// handleCouponClick(res)


	};


	const handleCloseModal = () => {
		setShowModal(false);
	};


	//capture likely error
	const onError = (data: any, actions: any) => {
		setErrorMessage("An Error occured with your payment ");
	};


	if (isLoading) {
		return (
			<Container className="spinner-container">
				{/* Loading... */}
				<div className="spinner-border" role="status">
					{/* <span className="visually-hidden">.</span> */}
				</div>
			</Container>

		);
	}





	const handlePaymentSelect = (selectedOption: any) => {

		// console.log('handlePaymentSelect', selectedOption)
		if (selectedOption == 1) {
			setShowPaypal(true);
			setShowEth(false);
			// console.log('setShowEth', showEth)
			// console.log('setShowPaypal', showPaypal)
		} else if (selectedOption == 2) {
			setShowPaypal(false);
			setShowEth(true);
			// console.log('setShowEth', showEth)
			// console.log('setShowPaypal', showPaypal)
		}
	}

	const clickStepOne = () => {

		if (!stepOne) {
			setStepOne(true);
			setStepTwo(false);
			setStepThree(false);
		}


	}
	const handleModalOpen = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	}


	const handleCloseRemoveModal = () => {
		setShowRemoveModal(false);
	};

	const handleCloseErrorModal = () => {
		setShowErrorModal(false);
	};

	const remove = () => {

		if (removeType === 'coupon') {
			handleCouponClick({})
		}
		setShowRemoveModal(false);

	}

	function editCoupon() {
		// setCoupon({});
		setRemoveType('coupon');
		setRemoveMsg('Are you wanna remove this coupon?')
		setShowRemoveModal(true)



	}

	const handleShowCartModal = () => {
		setShowCartModal(true);
	};

	const handleCloseCartModal = () => {
		setShowCartModal(true);
	};


	const getCurrency = () => {

		const toEth = calculateTotalDiscountPriceConvertion() * ethRate;
		// console.log('eth', toEth)
		return toEth.toFixed(4);

	}



	const payWithEth = async () => {
		setShowProgressModal(true);
		try {
			const txResult = await nftCollection?.call("sendPayment", [], { value: ethers.utils.parseEther(getCurrency()) });
			// console.log('txResult', txResult);
			// setProgress(0);


			await placeOrder(txResult.receipt, 'cryptocurrency');
			// await simulateAsyncOperation(3000);


		} catch (e: any) {
			console.error("An error occurred trying to mint the NFT:", e);
			// toast.error(`An error occurred: ${e.message}`);
			// setShowProgressModal(false);
		}
	};



	return (
		<>
			{allowedToPurchase ? (
				<>
					<PayPalScriptProvider options={{ "client-id": client_id!, currency: currency.value, }}>
						<section className="py-5 placeholder-glow">
							<div className="container checkout-wrap">
								<div className="row">
									<div className="col-xl-8 col-lg-8 mb-4">
										<div className="card shadow-0 border" style={!stepOne ? { cursor: "pointer" } : { cursor: "default" }} onClick={clickStepOne}>
											<div className="px-0 py-4">
												<h5 className="card-title mb-0 px-4" >
													1. Items in Cart
													<span className={`float-end ${stepOne ? "d-none" : "d-block"
														}`}>
														<i className="bi bi-check-lg" style={{ color: "green" }}></i>
													</span>
												</h5>

												<hr className={`my-4 ${stepOne ? "d-block" : "d-none"
													}`} />
												{stepOne ? (
													<div className="px-4">
														{cart.map((item: any, index: number) => (
															<div className="d-flex align-items-center mb-4" key={index}>
																<div className="me-3 position-relative">

																	<img
																		src={item.image_src}
																		style={{ height: "96px", width: "96px" }}
																		className="img-sm rounded border"
																	/>
																</div>
																<div className="">
																	{/* <a href="#" className="nav-link"> */}
																	{item.title}
																	<div style={{ "fontSize": "14px" }}>{item.variant_title}</div>
																	{/* </a> */}
																	<div className="price text-muted">
																		Total: {getSymbolFromCurrency(currency.value)}{(parseFloat(priceConversion(item.price)) * item.quantity).toFixed(2)} | {item.quantity}{" "}
																		item(s)
																	</div>


																	<div
																		className="price text-muted"
																		style={{ fontSize: "12px" }}
																	>
																		{getSymbolFromCurrency(currency.value)}{priceConversion(item.price)} / per item
																	</div>
																</div>
															</div>
														))}






													</div>


												) : null}

												{!coupon.code ? (
													<div
														className={`px-4 pt-4 float-start ${stepOne ? "d-block" : "d-none"
															}`}>

														<p>Have a coupon? <span><a className="coupon-link" href="" onClick={(e) => handleModalOpen(e)}>Click here to enter your code.</a></span></p>


													</div>
												) : null}




												<div
													className={`px-4 pt-4 float-end ${stepOne ? "d-block" : "d-none"
														}`}>

													<button className="btn btn-light border me-2" onClick={continueToShip}>Continue</button>

												</div>

											</div>
										</div>

										<div className="card shadow-0 border mt-4" onClick={cancelOrder} style={stepThree ? { cursor: "pointer" } : { cursor: "default" }} >
											<div className="px-0 py-4">
												<h5 className="card-title mb-0 px-4">
													2. Shipping Info
													<span className={`float-end ${stepThree ? "d-block" : "d-none"
														}`}>
														<i className="bi bi-check-lg" style={{ color: "green" }}></i>
													</span>
												</h5>
												{/* <hr className="my-4" /> */}
												<hr className={`my-4 ${stepOne || stepThree ? "d-none" : "d-block"}`} />
												<div className={` ${stepOne || stepThree ? "d-none" : "d-block"}`}>

													{(add || edit) && !stepThree ? (
														<Form onSubmit={(e) => edit && !add ? editShippingAddress(e) : saveShippingAddress(e)}>
															<div className="px-4">
																<div className="col-sm-4 col-6 mb-3">
																	<p className="mb-0">Label</p>
																	<div className="form-outline">
																		<input
																			type="text"
																			id="typeText"
																			required
																			value={selectedAddress.name}
																			placeholder="Default, Home, Work"
																			onChange={(e) =>
																				setSelectedAddress({
																					...selectedAddress,
																					name: e.target.value,
																				})
																			}
																			className="form-control"
																		/>

																	</div>
																</div>
																{/* <Form id="shipping-form"> */}
																<div className="row">
																	<div className="col-6 mb-3">
																		<p className="mb-0">First name</p>
																		<div className="form-outline">
																			<input
																				type="text"
																				id="typeText"
																				required
																				name="first_name"
																				value={selectedAddress.firstname}
																				onChange={(e) =>
																					setSelectedAddress({
																						...selectedAddress,
																						firstname: e.target.value,
																					})
																				}
																				placeholder="Type here"
																				className="form-control"
																			/>
																		</div>
																	</div>

																	<div className="col-6">
																		<p className="mb-0">Last name</p>
																		<div className="form-outline">
																			<input
																				type="text"
																				required
																				id="typeText"
																				value={selectedAddress.lastname}
																				onChange={(e) =>
																					setSelectedAddress({
																						...selectedAddress,
																						lastname: e.target.value,
																					})
																				}
																				placeholder="Type here"
																				className="form-control"
																			/>
																		</div>
																	</div>

																	<div className="col-6 mb-3">
																		<p className="mb-0">Email</p>
																		<div className="form-outline">
																			<input
																				type="email"
																				required
																				id="typeEmail"
																				value={selectedAddress.email}
																				onChange={(e) =>
																					setSelectedAddress({
																						...selectedAddress,
																						email: e.target.value,
																					})
																				}
																				placeholder="example@gmail.com"
																				className="form-control"
																			/>
																		</div>
																	</div>

																	<div className="col-6 mb-3">
																		<p className="mb-0">Phone</p>
																		<div className="form-outline">
																			<input
																				type="tel"
																				required
																				id="typePhone"
																				value={selectedAddress.phone}
																				placeholder="Area Code + Phone Number"
																				style={phoneNumberError ? { borderColor: "red" } : { borderColor: "#dee2e6" }}
																				onChange={(e) =>
																					setSelectedAddress({
																						...selectedAddress,
																						phone: e.target.value,
																					})
																				}
																				className="form-control"
																			/>

																		</div>
																		{phoneNumberError ? (
																			<p className="text-danger mt-1 m-0">
																				<i className="bi bi-exclamation-circle pe-2"></i>
																				Please enter a valid Phone Number. Must be at least 6 characters.
																			</p>) : null}
																	</div>
																</div>

																<div className="row">
																	<div className="col-sm-12 mb-3">
																		<p className="mb-0">Address</p>
																		<div className="form-outline">
																			<input
																				type="text"
																				required
																				id="typeText"
																				value={selectedAddress.address}
																				onChange={(e) =>
																					setSelectedAddress({
																						...selectedAddress,
																						address: e.target.value,
																					})
																				}
																				placeholder="Enter address"
																				className="form-control"
																			/>
																		</div>
																	</div>





																	<div className="col-sm-6 mb-3">
																		<p className="mb-0">Country</p>
																		<Select
																			options={countries}
																			required
																			value={selectedCountry}
																			onChange={handleCountryChange}
																			placeholder="Select a country"
																			isSearchable // Enable search functionality
																		/>
																	</div>



																	<div className="col-sm-6 mb-3">
																		<p className="mb-0">Region</p>

																		<Select
																			options={regions}
																			required
																			value={selectedRegion}
																			onChange={handleRegionChange}
																			placeholder="Select a region/state"
																			isSearchable // Enable search functionality
																		/>

																	</div>

																	<div className="col-sm-6 mb-3">
																		<p className="mb-0">City</p>
																		<div className="form-outline">
																			<input
																				type="text"
																				required
																				id="typeText"
																				value={selectedAddress.city}
																				style={numberError ? { borderColor: "red" } : { borderColor: "#dee2e6" }}
																				onChange={(e) =>
																					setSelectedAddress({
																						...selectedAddress,
																						city: e.target.value,
																					})
																				}
																				className="form-control"
																			/>
																		</div>
																	</div>

																	<div className="col-sm-6 mb-3">
																		<p className="mb-0">Postal Code</p>
																		<div className="form-outline">
																			<input
																				type="text"
																				required
																				id="typeText"
																				value={selectedAddress.postal_code}
																				style={numberError ? { borderColor: "red" } : { borderColor: "#dee2e6" }}
																				onChange={(e) =>
																					setSelectedAddress({
																						...selectedAddress,
																						postal_code: e.target.value,
																					})
																				}
																				className="form-control"
																			/>
																		</div>
																		{numberError ? (<p className="text-danger mt-1 m-0">
																			<i className="bi bi-exclamation-circle pe-2"></i>
																			Please enter a valid Postal Code. Postal code must be a valid number with at least 4 characters
																		</p>) : null}
																	</div>

																</div>

																<div className="float-end">
																	{add ? (
																		<button
																			className="btn btn-light border me-2"
																			onClick={() => { setAdd(false); selectShippingAddress(addresses[0]) }}
																		>
																			Cancel
																		</button>
																	) : null

																	}

																	{edit ? (
																		<>
																			<button
																				className="btn btn-light border me-2"
																				onClick={() => { setEdit(false); }}
																			>
																				Cancel
																			</button>
																			<button
																				className="btn btn-primary shadow-0 border "
																			// onClick={editShippingAddress}
																			>
																				Edit Address
																			</button>
																		</>
																	) : (
																		<>

																			<button
																				className="btn btn-primary shadow-0 border"
																				// onClick={saveShippingAddress}
																				type="submit"
																			>
																				Add Address
																			</button>
																		</>

																	)}
																</div>


															</div>
														</Form>
													) : (
														<div className="card-container">
															{addresses.length != 0 ? (
																<Row className=" px-2 equal-heigh cards-row m-0">
																	{addresses.map((add: any, index: number) => (
																		<div className="col-lg-3 p-1 pt-3" key={index}>
																			<Card className={`ship-card h-100 ${selectedAddress.id === add.id ? "selected" : ""
																				}`} onClick={() => selectShippingAddress(add)} style={{ cursor: isClickable ? 'pointer' : 'default' }}>
																				<Card.Body>
																					<i className={`float-end bi bi-check-circle-fill ${selectedAddress.id === add.id ? "d-block" : "d-none"
																						}`}></i>
																					<p
																						className="m-0"
																						style={{
																							fontStyle: "italic",
																							textTransform: "capitalize",
																						}}
																					>
																						{add.name}:
																					</p>
																					<p className="m-0" style={{ fontWeight: "bold" }}>
																						<i className="bi bi-geo-alt pe-1"></i>
																						{add.firstname} {add.lastname}
																					</p>
																					<p className="m-0 text-muted">{add.email}</p>
																					<p className="m-0">{add.phone}</p>
																					<p className="m-0">{add.address},</p>
																					<p className="m-0">{add.city},</p>
																					<p className="m-0">
																						<span className="text-uppercase">{add.region},</span> {add.postal_code},
																					</p>
																					<p className="m-0">{add.country} </p>
																				</Card.Body>
																				<Card.Footer className="text-center">
																					<ButtonGroup vertical>
																						{/* <button
															className={`btn btn-light border me-2 mb-2 ${selectedAddress.id === add.id ? "d-none" : "d-block"
														}`}
																
																onClick={()=> selectShippingAddress(add)}
															>
																Select
															</button> */}

																						<button
																							className={`btn btn-primary shadow-0 border ${!add || !edit ? "d-block" : "d-none"}`}
																							onClick={() => editAddressForm(add)}
																						>
																							Edit Address
																						</button>
																					</ButtonGroup>

																				</Card.Footer>
																			</Card>
																		</div>
																	))}
																</Row>
															) : (
																<div className="p-2">Please add an address to proceed!</div>

															)}


															<div
																className={`px-4 pt-4 float-end ${stepOne || stepThree ? "d-none" : "d-block"
																	}`}
															>
																<button className={`btn btn-light border me-2 mb-2 ${shippingLoad ? "disabled" : ""
																	}`} onClick={savePayment}>Continue to Payment</button>
																<button
																	className="btn btn-primary shadow-0 border  mb-2"
																	onClick={addAddressForm}
																>
																	Add Address
																</button>
															</div>
															{/* <div className={`px-4 pt-4 float-end ${stepTwo ? "d-block" : "d-none"
												}`}>
												<button
													className="btn btn-primary border me-2 w-100 mb-2"
													onClick={cancelOrder}
												>
													Edit
												</button>
											</div> */}

														</div>
													)}

												</div>



											</div>
										</div>

										<div className="card shadow-0 border mt-4">
											<div className="px-0 py-4">
												<h5 className="card-title mb-0 px-4">3. Payment Option</h5>
												<hr className={`my-4 ${stepThree ? "d-block" : "d-none"}`} />
												{stepThree ? (
													<div className="px-4">
														<div className="col-6 mb-3">
															{/* <p className="mb-0">Phone</p> */}
															<div className="form-check">
																<input
																	type="radio"
																	id="paymentMethodPaypal"
																	name="paymentMethodPaypal"
																	className="form-check-input"
																	checked={showPaypal}
																	value={1}
																	onChange={(e) => handlePaymentSelect(e.target.value)}
																/>
																<label className="form-check-label" htmlFor="paymentMethodPaypal">
																	Paypal
																</label>

															</div>
															{/* <div className="form-check">
													<input
														type="radio"
														id="paymentEth"
														name="paymentEth"
														className="form-check-input"
														checked={showEth}
														value={2}
														onChange={(e) => handlePaymentSelect(e.target.value)}
													/>
													<label className="form-check-label" htmlFor="paymentEth">
														Pay with Ethereum: <span style={{ "fontWeight": "700" }}>{getCurrency()} ETH</span> <span className="text-muted" style={{ "fontSize": "14px" }}>≈ {getSymbolFromCurrency(currency.value)}{calculateTotalDiscountPriceConvertion().toFixed(2)}</span>
													</label>

												</div> */}
														</div>




														<div className="float-end payment-buttons">
															<button
																className="btn btn-primary border"
																onClick={cancelOrder}
															>
																Cancel
															</button>
															{/* <button className="btn btn-light shadow-0 border" onClick={payWithEth}>
													Place Order
												</button> */}

															{/* <Web3Button className="btn btn-light connect-btn"
													contractAddress={process.env.REACT_APP_PUBLIC_NFT_COLLECTION_ADDRESS!}
													action={() => payWithEth()}
													onError={(error) => alert("Something went wrong!" + error)}
												>
													Execute Action
												</Web3Button> */}

															{showPaypal ? (
																<div className="float-end paypal-button-wrap" >

																	<PayPalButtons
																		style={{ layout: "horizontal", label: "pay", color: "white", height: 55, tagline: false }}
																		fundingSource="paypal"
																		createOrder={createOrder}
																		onApprove={onApprove}
																	/>
																</div>
															) : null}
														</div>

													</div>
												) : (
													<></>
												)}
											</div>
										</div>


									</div>



									<div className="col-xl-4 col-lg-4 ">

										<div className="ms-lg-4 mt-4 mt-lg-0" style={{ maxWidth: "900px" }}>
											{Object.keys(coupon).length && coupon.amount != 0 ? (
												<div className="card my-3 border shadow-0">
													<div className="card-body">

														<div>
															<p>
																<i className="bi bi-ticket me-2"></i>
																Coupon
																{/* <a className="coupon-link ps-2" href="" onClick={(e) => handleModalOpen(e)}>Edit</a> */}
																<i className="float-end bi bi-x-lg" style={{ color: "red", cursor: "pointer" }} onClick={editCoupon}></i>
																{/* <a className="float-end">Edit</a> */}
															</p>
															<p>
																{coupon.name}
															</p>
															{/* <p className="text-danger">*Excluding Shipping Cost</p> */}
															<p className="text-muted">
																Expiration Date:
																{' ' + moment(coupon.expiration_date).format('MM/DD/YYYY, h:mm A')}
															</p>
															<p className="text-danger fst-italic">*Excluding Shipping Cost</p>
														</div>


													</div>
												</div>
											) : null}
											<h6 className="mb-3">Summary</h6>
											<div className="d-flex justify-content-between">
												<p className="mb-2">Subtotal:</p>
												<p className="mb-2">{getSymbolFromCurrency(currency.value)}{calculateTotalPriceConvertion(cart)}</p>
											</div>
											<div className="d-flex justify-content-between">
												<p className="mb-2">Discount:</p>
												<p className="mb-2 text-success">-{getSymbolFromCurrency(currency.value)}{(coupon.amount ? calculateCoupon(coupon.amount) : '0.00')}</p>
											</div>
											<div className="d-flex justify-content-between">
												<p className="mb-2">Shipping cost:</p>

												<p className="mb-2">+ {getSymbolFromCurrency(currency.value)}{priceConversion(shippingCost / 100)}</p>
											</div>
											<hr />
											<div className="d-flex justify-content-between">
												<p className="mb-2">Total price:</p>
												{calculateTotalDiscountPriceConvertion() < 0 ? (
													<p className="mb-2 fw-bold">-{getSymbolFromCurrency(currency.value)}{Math.abs(calculateTotalDiscountPriceConvertion()).toFixed(2)}</p>
												) : (
													<p className="mb-2 fw-bold">{getSymbolFromCurrency(currency.value)}{calculateTotalDiscountPriceConvertion().toFixed(2)}</p>
												)}
												{/* <p className="mb-2 fw-bold">
									{getSymbolFromCurrency(currency.value)}{calculateTotalDiscountPriceConvertion(cart)}
									</p> */}
											</div>


											<Link className="btn btn-primary w-100 border mt-2" to={`/store`}> Back to shop </Link>







										</div>
									</div>
								</div>
							</div>


							<ToastContainer
								position="top-center"
								autoClose={5000}
								hideProgressBar={false}
								newestOnTop
								closeOnClick
								rtl={false}
								pauseOnFocusLoss
								draggable
								pauseOnHover
								theme="light"
							/>

							<Modal
								show={showProgressModal}

							// onHide={handleCloseProModal}

							>

								<Modal.Body className="py-4">
									<div className="d-flex align-items-center">
										<strong role="status">Processing Order...</strong>


										<div className="spinner-border ms-auto" aria-hidden="true"></div>

									</div>


								</Modal.Body>


							</Modal>

							<Modal
								show={showModal}
								onHide={handleCloseModal}

							>
								<Modal.Header closeButton>
									<Modal.Title className="p-0">Have a coupon?</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<form>
										{/* {connected ? ( */}
										<div className="form-group">
											{/* <label className="form-label">Have coupon?</label> */}
											<div className="input-group">
												<input type="text" className="form-control border" required name="" placeholder="Coupon code" onChange={(e) =>
													setCouponCode(e.target.value)
												} />

											</div>
										</div>
										{/* ) : null

											} */}

									</form>
								</Modal.Body>
								<Modal.Footer className='d-flex'>

									<button onClick={(e) => getCouponDiscount(e)} type="submit" className="btn btn-light border">Apply</button>




								</Modal.Footer>

							</Modal>



							<Modal
								show={showRemoveModal}
								onHide={handleCloseRemoveModal}

							>
								<Modal.Header closeButton>
									{/* <Modal.Title>Uh oh!</Modal.Title> */}
								</Modal.Header>
								<Modal.Body>
									<p>{removeMsg}</p>
								</Modal.Body>
								<Modal.Footer className='d-flex'>

									<Button className='btn btn-primary flex-grow-1' variant="secondary" onClick={handleCloseRemoveModal}>
										No
									</Button>
									<Button className='btn btn-light border flex-grow-1' variant="secondary" onClick={remove}>
										Yes
									</Button>




								</Modal.Footer>
							</Modal>
							<Modal
								show={showErrorModal}
								onHide={handleCloseErrorModal}

							>
								<Modal.Header className="ps-3">
									<Modal.Title className="text-danger p-0">{errorMsgTitle}</Modal.Title>
								</Modal.Header>
								<Modal.Body className="ps-3">
									<p>{errorMsg}</p>
								</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" className="btn-primary" onClick={handleCloseErrorModal}>
										Close
									</Button>

								</Modal.Footer>

							</Modal>


						</section>
					</PayPalScriptProvider >

				</>
			) : (
				<Container>

					<div className="d-flex justify-content-center align-items-center my-5">
						{showErrorPerm ? (
							<div className="col-md-4">

								<div className="border border-3 border-danger"></div>
								<div className="card  bg-white shadow p-5">
									<div className="mb-4 text-center">
										<svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" fill="#dc3545" className="bi bi-x-circle" viewBox="0 0 16 16">
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
											<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
										</svg>
									</div>
									<div className="text-center">
										{/* <h1>{errorMsgTitle}</h1> */}
										<p>{errorMsg} </p>
									</div>
								</div>
							</div>
						) : null}

					</div>
				</Container>
			)}

		</>

	);
}

export default Checkout;
