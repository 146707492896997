const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const username =  process.env.REACT_APP_CLIENT_ID;
const password =process.env. REACT_APP_APP_SECRET;
const encodedCredentials = btoa(`${username}:${password}`);
const api_key = process.env.REACT_APP_API_KEY;

export const getOrders = async (wallet_id: any) => {
	// const res: any = [];


	const response = await fetch(`${API_BASE_URL}/api/order/wallet/${wallet_id}`, {
		headers: {
			'Authorization': `Bearer ${api_key}`,
		}
	});


	if (!response.ok) {
		throw new Error(`API request failed with status ${response.status}`);
	}
	const res = await response.json();

	// const printify_orders = await getOrders.json();
	// const x = JSON.parse(res);
	return res.orders;
}


export const fetchOrder = async (order_id: any, paypal_id: any) => {
	const [res, prod, paypal_order_id] = await Promise.all([
		fetch(`${API_BASE_URL}/api/printify/order-history/${order_id}`, {
			headers: {
				'Authorization': `Bearer ${api_key}`,
			}
		}).then((response) => response.json()),
		fetch(`${API_BASE_URL}/api/printify/products`, {
			headers: {
				'Authorization': `Bearer ${api_key}`,
			}
		}).then((response) => response.json()),
		getPaypalOrderID(paypal_id),
	]);

	const order = JSON.parse(res);
	const products = prod;
	const paypal_order = await getPaypalOrder(paypal_order_id);

	const line =  paypal_order.items.map((item: any) => {
		const p = products.find((p: any) => p.variants.some((variant: any) => variant.sku === item.sku));
		const variant_id = p.variants.find((variant: any) => variant.sku === item.sku)?.id;
		const selectedImage =  getImageSrc(p, variant_id);
		return { ...item, images: selectedImage };
	});

	order.line_items = line;
	order.breakdown = paypal_order.amount.breakdown;

	return order;
};

export const getPaypalOrderID = async (paypal_id: any) => {
	const url = `https://api-m.sandbox.paypal.com/v2/payments/captures/${paypal_id}`;
	const paypal_order = await fetch(url, {
		headers: {
			'Authorization': `Basic ${encodedCredentials}`,
		},
	}).then((response) => response.json());

	return paypal_order.supplementary_data.related_ids.order_id;
};

export const getPaypalOrder = async (paypal_order_id: any) => {
	const url = `https://api-m.sandbox.paypal.com/v2/checkout/orders/${paypal_order_id}`;
	const paypal_order = await fetch(url, {
		headers: {
			'Authorization': `Basic ${encodedCredentials}`,
		},
	}).then((response) => response.json());

	return paypal_order.purchase_units[0];
};

export const checkIfAllowedToPurchase = async (nft_token_id: any, wallet_address: any) => {

	const response = await fetch(API_BASE_URL + `/api/order/check_if_allowed_to_purchase/` + nft_token_id + `?wallet_address=` + wallet_address, {
		headers: {
			'Authorization': `Bearer ${api_key}`,
		}
	});

	if (!response.ok) {
		throw new Error(`API request failed with status ${response.status}`);
	}
	const res = await response.json();
	return res;
};

function getImageSrc(product: any , variant_id:any) {
	// console.log('type', type);
	// setSortType(type)
	let src = '';
	if (product.custom_images.length != 0) {
		let custom_src = product.custom_images.find((image: any) => Boolean(Number(image.is_default)))?.src;
		if (custom_src) {
			src = custom_src;
		} else {
			src = product.custom_images[0].src;
		}
	} else {
		const variant = product.images.find((image: any) => image.variant_ids.includes(variant_id.toString()));
		src = variant.src;
	}

	// console.log('src', src)
	return src;

}



