import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";


export default function Root({ rates, currency, cart, setCart,  handleCurrencyChange, showCartModal, setShowCartModal, handleChange, handleSearch, tokenIds, handleNewToken }: any) {
  
    
  return (
    <div>
       <Navbar rates={rates} currency={currency} cart={cart} setCart={setCart} handleCurrencyChange={handleCurrencyChange} showCartModal={showCartModal} setShowCartModal={setShowCartModal} handleChange={handleChange} handleSearch={handleSearch} tokenIds={tokenIds} handleNewToken={handleNewToken}/>
        <div id="detail">
            <Outlet />
        </div>
        <Footer/>
    </div>
  );
}