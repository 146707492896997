import "../styles/Footer.css";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";


const Footer = () => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.matchMedia('(max-width: 767px)').matches;
            setIsMobile(isMobile);
        };

        handleResize(); // Check initial viewport size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="footer-wrap">
            <div className="container-fluid">
                <div>
                    <img className="footer-img" alt="" src="/images/middleclass-logo.png" />

                </div>
                <div className="row">
                    <div className="col-lg-6 px-lg-5 ">
                        <p>ABOUT</p>
                        <p className="about-txt">
                            MiddleClass.sg is an independent online publication that showcases the best of Singapore, Korea & Asia. Through our travel guides and videos, which introduce the best to eat, do, play & visit in South Korea, MiddleClass.sg attracts nearly 100,000 visitors per month and has won Outstanding Content Awards from The Embassy of the Republic of Korea in Singapore for 2 consecutive years.
                        </p>
                    </div>
                    <div className="col-lg-3 px-lg-5">
                        <p>QUICKLINKS</p>
                        <ul>
                            <li>
                                <Link className="footer-link" to={'/'}>Home</Link>
                            </li>
                            <li>
                                <Link className="footer-link" to={'/store'}>Store</Link>
                            </li>
                            <li>
                                <a className="footer-link" href="https://opensea.io/collection/nokko" target="_blank">nokko NFT Collection</a>

                            </li>
                            <li>
                                <a className="footer-link" href="https://middleclass.sg/korea" target="_blank">Explore Korea with nokko</a>
                                {/* <Link className="footer-link" to={'https://middleclass.sg/korea'}>Explore Korea with nokko</Link> */}
                            </li>

                        </ul>

                    </div>
                    <div className="col-lg-3 px-lg-5">
                        <p>FOLLOW US</p>
                        {isMobile ? (
                            <div className="d-flex flex-row m-4 ms-0">
                                <div className="col-md-4 pe-4 ">
                                    <a href="https://www.instagram.com/middleclasssg/" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.771" height="26.766" viewBox="0 0 26.771 26.766">
                                            <path id="instagram" d="M13.382,9.36a6.862,6.862,0,1,0,6.862,6.862A6.851,6.851,0,0,0,13.382,9.36Zm0,11.324a4.461,4.461,0,1,1,4.461-4.461,4.47,4.47,0,0,1-4.461,4.461Zm8.744-11.6a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,22.126,9.08ZM26.671,10.7A7.921,7.921,0,0,0,24.509,5.1,7.973,7.973,0,0,0,18.9,2.934c-2.21-.125-8.833-.125-11.043,0A7.962,7.962,0,0,0,2.249,5.09,7.947,7.947,0,0,0,.087,10.7c-.125,2.21-.125,8.833,0,11.043a7.921,7.921,0,0,0,2.162,5.608,7.983,7.983,0,0,0,5.608,2.162c2.21.125,8.833.125,11.043,0a7.921,7.921,0,0,0,5.608-2.162,7.973,7.973,0,0,0,2.162-5.608c.125-2.21.125-8.827,0-11.037ZM23.816,24.112a4.517,4.517,0,0,1-2.544,2.544c-1.762.7-5.943.538-7.89.538s-6.134.155-7.89-.538a4.517,4.517,0,0,1-2.544-2.544c-.7-1.762-.538-5.943-.538-7.89s-.155-6.134.538-7.89A4.517,4.517,0,0,1,5.492,5.789c1.762-.7,5.943-.538,7.89-.538s6.134-.155,7.89.538a4.517,4.517,0,0,1,2.544,2.544c.7,1.762.538,5.943.538,7.89S24.515,22.356,23.816,24.112Z" transform="translate(0.007 -2.84)" fill="#fcf9ee" />
                                        </svg>
                                    </a>


                                </div>
                                <div className="col-md-4 pe-4 ">
                                    <a href="https://www.facebook.com/MiddleClassSG/" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.771" height="26.609" viewBox="0 0 26.771 26.609">
                                            <path id="facebook" d="M27.417,14.031A13.386,13.386,0,1,0,11.939,27.255V17.9h-3.4V14.031h3.4V11.082c0-3.355,2-5.207,5.056-5.207a20.6,20.6,0,0,1,3,.261V9.428H18.3a1.935,1.935,0,0,0-2.181,2.09v2.513h3.712L19.241,17.9H16.122v9.354A13.391,13.391,0,0,0,27.417,14.031Z" transform="translate(-0.645 -0.645)" fill="#fcf9ee" />
                                        </svg>
                                    </a>


                                </div>
                                <div className="col-md-4 pe-4 ">
                                    <a href="https://www.youtube.com/channel/UCC4kjNTZJGWuN91w10IT03Q" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.771" height="18.824" viewBox="0 0 26.771 18.824">
                                            <path id="youtube" d="M27.306,7.633a3.364,3.364,0,0,0-2.367-2.382c-2.088-.563-10.459-.563-10.459-.563s-8.372,0-10.459.563A3.364,3.364,0,0,0,1.653,7.633a35.288,35.288,0,0,0-.559,6.486A35.288,35.288,0,0,0,1.653,20.6,3.314,3.314,0,0,0,4.02,22.948c2.088.563,10.459.563,10.459.563s8.372,0,10.459-.563A3.314,3.314,0,0,0,27.306,20.6a35.288,35.288,0,0,0,.559-6.486A35.288,35.288,0,0,0,27.306,7.633ZM11.741,18.1V10.138l7,3.981-7,3.98Z" transform="translate(-1.094 -4.688)" fill="#fcf9ee" />
                                        </svg>
                                    </a>
                                </div>
                                <div className="col-md-4 pe-4 ">
                                    <a href="https://discord.com/invite/nokko" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.657" height="20.31" viewBox="0 0 26.657 20.31">
                                            <path id="discord" d="M23.694,3.06a.068.068,0,0,0-.035-.032,21.991,21.991,0,0,0-5.426-1.682.082.082,0,0,0-.087.041,15.3,15.3,0,0,0-.676,1.387,20.3,20.3,0,0,0-6.094,0,14.034,14.034,0,0,0-.686-1.387.086.086,0,0,0-.087-.041A21.929,21.929,0,0,0,5.177,3.028a.078.078,0,0,0-.036.031A22.482,22.482,0,0,0,1.2,18.225a.091.091,0,0,0,.035.062,22.109,22.109,0,0,0,6.656,3.363.086.086,0,0,0,.094-.031,15.786,15.786,0,0,0,1.362-2.214.084.084,0,0,0-.046-.117A14.561,14.561,0,0,1,7.223,18.3a.085.085,0,0,1-.008-.142c.14-.1.28-.214.413-.324a.082.082,0,0,1,.086-.012,15.77,15.77,0,0,0,13.4,0,.082.082,0,0,1,.087.011c.133.11.273.22.414.325a.085.085,0,0,1-.007.142,13.665,13.665,0,0,1-2.08.99.085.085,0,0,0-.045.118,17.729,17.729,0,0,0,1.361,2.213.085.085,0,0,0,.094.032A22.035,22.035,0,0,0,27.6,18.288a.085.085,0,0,0,.035-.061A22.333,22.333,0,0,0,23.694,3.06ZM10,15.2a2.561,2.561,0,0,1-2.4-2.686A2.548,2.548,0,0,1,10,9.827a2.533,2.533,0,0,1,2.4,2.686A2.548,2.548,0,0,1,10,15.2Zm8.858,0a2.561,2.561,0,0,1-2.4-2.686,2.548,2.548,0,0,1,2.4-2.686,2.533,2.533,0,0,1,2.4,2.686A2.541,2.541,0,0,1,18.858,15.2Z" transform="translate(-1.092 -1.344)" fill="#fcf9ee" />
                                        </svg>
                                    </a>
                                </div>
                                <div className="col-md-4 pe-4 ">
                                    <a href="https://twitter.com/nokkoNFT" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25.007" height="20.31" viewBox="0 0 25.007 20.31">
                                            <path id="twitter" d="M22.437,7.238c.016.222.016.444.016.666A14.482,14.482,0,0,1,7.87,22.487,14.483,14.483,0,0,1,0,20.186a10.6,10.6,0,0,0,1.238.063A10.264,10.264,0,0,0,7.6,18.06a5.134,5.134,0,0,1-4.792-3.554,6.463,6.463,0,0,0,.968.079,5.421,5.421,0,0,0,1.349-.175,5.126,5.126,0,0,1-4.11-5.03V9.317a5.162,5.162,0,0,0,2.317.651A5.133,5.133,0,0,1,1.745,3.113,14.568,14.568,0,0,0,12.313,8.476,5.786,5.786,0,0,1,12.186,7.3,5.13,5.13,0,0,1,21.056,3.8a10.091,10.091,0,0,0,3.253-1.238,5.111,5.111,0,0,1-2.253,2.824,10.275,10.275,0,0,0,2.951-.793,11.017,11.017,0,0,1-2.57,2.65Z" transform="translate(0 -2.177)" fill="#fcf9ee" />
                                        </svg>
                                    </a>
                                </div>
                                <div className="col-md-4 pe-4 ">
                                    <a href="https://www.tiktok.com/@middleclass.sg" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.212" height="23.101" viewBox="0 0 20.212 23.101">
                                            <path id="tiktok" d="M20.217,9.473A9.48,9.48,0,0,1,14.676,7.7v8.066A7.336,7.336,0,1,1,8.348,8.5v4.057A3.368,3.368,0,1,0,10.7,15.767V0h3.971a5.469,5.469,0,0,0,.084,1h0a5.514,5.514,0,0,0,2.433,3.62,5.48,5.48,0,0,0,3.024.909Z" transform="translate(-0.005)" fill="#fcf9ee" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div className="row m-4 ms-0">
                                <div className="col-md-4 px-0 py-2 ">
                                    <a href="https://www.instagram.com/middleclasssg/" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.771" height="26.766" viewBox="0 0 26.771 26.766">
                                            <path id="instagram" d="M13.382,9.36a6.862,6.862,0,1,0,6.862,6.862A6.851,6.851,0,0,0,13.382,9.36Zm0,11.324a4.461,4.461,0,1,1,4.461-4.461,4.47,4.47,0,0,1-4.461,4.461Zm8.744-11.6a1.6,1.6,0,1,1-1.6-1.6A1.6,1.6,0,0,1,22.126,9.08ZM26.671,10.7A7.921,7.921,0,0,0,24.509,5.1,7.973,7.973,0,0,0,18.9,2.934c-2.21-.125-8.833-.125-11.043,0A7.962,7.962,0,0,0,2.249,5.09,7.947,7.947,0,0,0,.087,10.7c-.125,2.21-.125,8.833,0,11.043a7.921,7.921,0,0,0,2.162,5.608,7.983,7.983,0,0,0,5.608,2.162c2.21.125,8.833.125,11.043,0a7.921,7.921,0,0,0,5.608-2.162,7.973,7.973,0,0,0,2.162-5.608c.125-2.21.125-8.827,0-11.037ZM23.816,24.112a4.517,4.517,0,0,1-2.544,2.544c-1.762.7-5.943.538-7.89.538s-6.134.155-7.89-.538a4.517,4.517,0,0,1-2.544-2.544c-.7-1.762-.538-5.943-.538-7.89s-.155-6.134.538-7.89A4.517,4.517,0,0,1,5.492,5.789c1.762-.7,5.943-.538,7.89-.538s6.134-.155,7.89.538a4.517,4.517,0,0,1,2.544,2.544c.7,1.762.538,5.943.538,7.89S24.515,22.356,23.816,24.112Z" transform="translate(0.007 -2.84)" fill="#fcf9ee" />
                                        </svg>
                                    </a>

                                </div>
                                <div className="col-md-4 px-0 py-2 ">
                                    <a href="https://www.facebook.com/MiddleClassSG/" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.771" height="26.609" viewBox="0 0 26.771 26.609">
                                            <path id="facebook" d="M27.417,14.031A13.386,13.386,0,1,0,11.939,27.255V17.9h-3.4V14.031h3.4V11.082c0-3.355,2-5.207,5.056-5.207a20.6,20.6,0,0,1,3,.261V9.428H18.3a1.935,1.935,0,0,0-2.181,2.09v2.513h3.712L19.241,17.9H16.122v9.354A13.391,13.391,0,0,0,27.417,14.031Z" transform="translate(-0.645 -0.645)" fill="#fcf9ee" />
                                        </svg>
                                    </a>
                                </div>
                                <div className="col-md-4 px-0 py-2 ">
                                    <a href="https://www.youtube.com/channel/UCC4kjNTZJGWuN91w10IT03Q" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.771" height="18.824" viewBox="0 0 26.771 18.824">
                                            <path id="youtube" d="M27.306,7.633a3.364,3.364,0,0,0-2.367-2.382c-2.088-.563-10.459-.563-10.459-.563s-8.372,0-10.459.563A3.364,3.364,0,0,0,1.653,7.633a35.288,35.288,0,0,0-.559,6.486A35.288,35.288,0,0,0,1.653,20.6,3.314,3.314,0,0,0,4.02,22.948c2.088.563,10.459.563,10.459.563s8.372,0,10.459-.563A3.314,3.314,0,0,0,27.306,20.6a35.288,35.288,0,0,0,.559-6.486A35.288,35.288,0,0,0,27.306,7.633ZM11.741,18.1V10.138l7,3.981-7,3.98Z" transform="translate(-1.094 -4.688)" fill="#fcf9ee" />
                                        </svg>
                                    </a>

                                </div>
                                <div className="col-md-4 px-0 py-2 ">
                                    <a href="https://discord.com/invite/nokko" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.657" height="20.31" viewBox="0 0 26.657 20.31">
                                            <path id="discord" d="M23.694,3.06a.068.068,0,0,0-.035-.032,21.991,21.991,0,0,0-5.426-1.682.082.082,0,0,0-.087.041,15.3,15.3,0,0,0-.676,1.387,20.3,20.3,0,0,0-6.094,0,14.034,14.034,0,0,0-.686-1.387.086.086,0,0,0-.087-.041A21.929,21.929,0,0,0,5.177,3.028a.078.078,0,0,0-.036.031A22.482,22.482,0,0,0,1.2,18.225a.091.091,0,0,0,.035.062,22.109,22.109,0,0,0,6.656,3.363.086.086,0,0,0,.094-.031,15.786,15.786,0,0,0,1.362-2.214.084.084,0,0,0-.046-.117A14.561,14.561,0,0,1,7.223,18.3a.085.085,0,0,1-.008-.142c.14-.1.28-.214.413-.324a.082.082,0,0,1,.086-.012,15.77,15.77,0,0,0,13.4,0,.082.082,0,0,1,.087.011c.133.11.273.22.414.325a.085.085,0,0,1-.007.142,13.665,13.665,0,0,1-2.08.99.085.085,0,0,0-.045.118,17.729,17.729,0,0,0,1.361,2.213.085.085,0,0,0,.094.032A22.035,22.035,0,0,0,27.6,18.288a.085.085,0,0,0,.035-.061A22.333,22.333,0,0,0,23.694,3.06ZM10,15.2a2.561,2.561,0,0,1-2.4-2.686A2.548,2.548,0,0,1,10,9.827a2.533,2.533,0,0,1,2.4,2.686A2.548,2.548,0,0,1,10,15.2Zm8.858,0a2.561,2.561,0,0,1-2.4-2.686,2.548,2.548,0,0,1,2.4-2.686,2.533,2.533,0,0,1,2.4,2.686A2.541,2.541,0,0,1,18.858,15.2Z" transform="translate(-1.092 -1.344)" fill="#fcf9ee" />
                                        </svg>
                                    </a>

                                </div>
                                <div className="col-md-4 px-0 py-2 ">
                                    <a href="https://twitter.com/nokkoNFT" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25.007" height="20.31" viewBox="0 0 25.007 20.31">
                                            <path id="twitter" d="M22.437,7.238c.016.222.016.444.016.666A14.482,14.482,0,0,1,7.87,22.487,14.483,14.483,0,0,1,0,20.186a10.6,10.6,0,0,0,1.238.063A10.264,10.264,0,0,0,7.6,18.06a5.134,5.134,0,0,1-4.792-3.554,6.463,6.463,0,0,0,.968.079,5.421,5.421,0,0,0,1.349-.175,5.126,5.126,0,0,1-4.11-5.03V9.317a5.162,5.162,0,0,0,2.317.651A5.133,5.133,0,0,1,1.745,3.113,14.568,14.568,0,0,0,12.313,8.476,5.786,5.786,0,0,1,12.186,7.3,5.13,5.13,0,0,1,21.056,3.8a10.091,10.091,0,0,0,3.253-1.238,5.111,5.111,0,0,1-2.253,2.824,10.275,10.275,0,0,0,2.951-.793,11.017,11.017,0,0,1-2.57,2.65Z" transform="translate(0 -2.177)" fill="#fcf9ee" />
                                        </svg>
                                    </a>
                                </div>
                                <div className="col-md-4 px-0 py-2 ">
                                    <a href="https://www.tiktok.com/@middleclass.sg" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.212" height="23.101" viewBox="0 0 20.212 23.101">
                                            <path id="tiktok" d="M20.217,9.473A9.48,9.48,0,0,1,14.676,7.7v8.066A7.336,7.336,0,1,1,8.348,8.5v4.057A3.368,3.368,0,1,0,10.7,15.767V0h3.971a5.469,5.469,0,0,0,.084,1h0a5.514,5.514,0,0,0,2.433,3.62,5.48,5.48,0,0,0,3.024.909Z" transform="translate(-0.005)" fill="#fcf9ee" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        )}

                    </div>
                </div>


            </div>
            {isMobile ? (
                <div className="copyright-txt ow text-center">
                    <div className="col-12 px-3">
                        ©2023 MIDDLECLASS
                    </div>
                    <div className=" col-12 px-3">
                        Terms & Conditions | Privacy Policy
                    </div>

                </div>
            ) : (
                <div className="copyright-txt d-flex justify-content-center">
                    {/* <a href="#" className="footer-link"> */}
                        <div className="px-3 border-end">
                            ©2023 MIDDLECLASS.SG
                        </div>
                    {/* </a> */}
                    {/* <a href="#" className="footer-link"> */}
                        <div className="px-3 border-end">
                            Terms & Conditions
                        </div>
                    {/* </a> */}

                    {/* <a href="#" className="footer-link"> */}
                        <div className="px-3">
                            Privacy Policy
                        </div>
                    {/* </a> */}


                </div>
            )}

        </div>

    );
}


export default Footer;
