import { useAddress, useWallet } from "@thirdweb-dev/react";
import { useLoaderData } from "react-router-dom";
import { getOwnedNft } from "./api/nfts";
import { NFTs } from "./api/nfts";
import "./styles/Home.css";
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { createBrowserRouter, RouterProvider } from "react-router-dom";


// PAGES
import Root from "./routes/roots";
import ErrorPage from "./error-page";

import Products from "./products";
import ProductDetails from "./product-details";

import MyNfts from "./my-nfts";

import OrderHistory from "./order-history";
import ShoppingCart from "./components/cart/shopping-cart";

import Checkout from "./components/cart/checkout";
import ThankYouPage from "./components/cart/thank-you";
import NftList from "./nft-list";
// import Test from "./test";
import { saveWallet, getCurrencyRates } from "./api/wallets";
import HomePage from "./homepage";

import Search from "./components/Search";



export default function App() {

  const connected = useWallet();
  const address = useAddress();
  const [show, setShow] = useState(true);
  // const [searchData, setSearchData] = useState({});
  const [searchData, setSearchData] = useState<any>(() => {
    const searchData = localStorage.getItem('searchData');
    return searchData ? JSON.parse(searchData) : {};
  });
  const [cart, setCart] = useState<any[]>(() => {
    const storedCart = localStorage.getItem('cart');
    return storedCart ? JSON.parse(storedCart) : [];
  });
  const [coupon, setCoupon] = useState<any>(() => {
    const storedCoupon = localStorage.getItem('coupon');
    return storedCoupon ? JSON.parse(storedCoupon) : {};
  });
  const [showModal, setShowModal] = useState(false);
  const [showCartModal, setShowCartModal] = useState(false);
  const [walletId, setWalletId] = useState(() => {
    const storedwalletId = localStorage.getItem('walletId');
    return storedwalletId ? JSON.parse(storedwalletId) : '';
  });
  const [currency, setCurrency] = useState<any>({
    value: 'USD',
    label: 'USD',
    rate: 1
  });
  const [currencyRates, setCurrencyRates] = useState<any[]>([]);
  const [user, setUser] = useState({});

  const [allowedToPurchase, setAllowedToPurchase] = useState(() => {
    const allowedToPurchase = localStorage.getItem('allowedToPurchase');
    return allowedToPurchase ? JSON.parse(allowedToPurchase) : '';
  });


  const [tokenIds, setTokenIds] = useState<any>();

  useEffect(() => {
    if (address) {
      const getNftTokenId = async () => {
        const owned: any = await getOwnedNft(address);
        // console.log('ownde', owned.length);
        if (owned.length != 0) {
          // console.log('owndesssss', owned);
          setTokenIds(owned)
          
        } else {
          setTokenIds('empty')
        }

        // console.log('toke', tokenIds);
        
        

      }
      getNftTokenId();
    }

  }, [address]);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {
    localStorage.setItem('allowedToPurchase', JSON.stringify(allowedToPurchase));
  }, [allowedToPurchase]);

  useEffect(() => {
    localStorage.setItem('coupon', JSON.stringify(coupon));
  }, [coupon]);

  useEffect(() => {
    localStorage.setItem('searchData', JSON.stringify(searchData));
  }, [searchData]);


  useEffect(() => {
    localStorage.setItem('walletId', JSON.stringify(walletId));
  }, [walletId]);
  // setCoupon({ ...coupon, amount: 0 } );

  const handleClick = (item: any, quantity: any) => {
    // Update cart item quantity if already in cart
    if (cart.some((cartItem) => cartItem.variant_sku === item.variant_sku)) {
      setCart((cart) =>
        cart.map((cartItem) =>
          cartItem.variant_sku === item.variant_sku
            ? {
              ...cartItem,
              quantity: quantity
            }
            : cartItem
        )
      );
      return;
    }

    // Add to cart
    setCart((cart) => [
      ...cart,
      { ...item, quantity: quantity } // <-- initial amount 1
    ]);
  };



  const handleCouponClick = (coupon: any) => {
    // console.log('handleCouponClick', coupon)
    // const newAmount = convertCoupon(coupon);

    // const newCouponAmount = coupon.amount
    setCoupon(coupon)
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleChange = (variant_sku: any, d: any) => {
    setCart((cart) =>
      cart.flatMap((cartItem) =>
        cartItem.variant_sku === variant_sku
          ? parseInt(d) < 1
            ? [] // <-- remove item if amount will be less than 1
            : [
              {
                ...cartItem,
                quantity: parseInt(d)
              }
            ]
          : [cartItem]
      )
    );
  };

  useEffect(() => {


    // if(Object.keys(currencyRates).length == 0) {
    const getCurrency = async () => {
      // const rates = {};
      const res = await getCurrencyRates();
      // const targetValues = ['USD', 'SGD'];
      // const tempList = Object.keys(res.response.rates).filter((value) => targetValues.includes(value));
      ;
      //  console.log('tempList',tempList )

      const currencyOption = Object.keys(res.results).map((currency: any) => ({
        value: currency,
        label: currency,
        rate: res.results[currency]

      }));
      setCurrencyRates(currencyOption);

      // setCurrencyRates(currencyOption)


      // save wallet address id and then send it to checkout 
      // console.log('raetes',currencyOption);
    }

    getCurrency();
    // }
    // console.log('currencyRates', currencyRates);
    // console.log('currencyRates', currencyRates['SGD']);



  }, []);

  const handleNewToken = (value: any) => {
    // console.log('setCurrency', value);
    setTokenIds(value)
  };

  const handleCurrencyChange = (value: any) => {
    // console.log('setCurrency', value);
    setCurrency(value)
  };

  const handleUserChange = (value: any) => {
    // console.log('handleUserChange', value);
    setUser(value)
  };

  

  const handlePurchaseAuth = (value: any) => {
    // console.log('handleUserChange', value);
    setAllowedToPurchase(value)
  };

  const handleSearch = (value: any, keyword: any) => {
    // console.log('handleSearch', value);
    setSearchData({ ...value, keyword: keyword })
  };



  useEffect(() => {
    if (connected && address) {

      const saveWalletAddres = async (address: any) => {
        const res = await saveWallet(address);
        if (res.id) {
          setWalletId(res.id);
        }

      }

      saveWalletAddres(address);

    }
  }, [connected, address]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root rates={currencyRates} currency={currency} cart={cart} setCart={setCart} handleCurrencyChange={handleCurrencyChange} showCartModal={showCartModal} setShowCartModal={setShowCartModal} handleChange={handleChange} handleSearch={handleSearch} tokenIds={tokenIds} handlePurchaseAuth={handlePurchaseAuth} handleNewToken={handleNewToken}/>,
      errorElement: <ErrorPage />,
      children: [
        { index: true, element: <HomePage handleNewToken={handleNewToken} tokenIds={tokenIds} /> },
        {
          path: "/store",
          // element: <Products />,
          children: [
            { index: true, element: <Products rates={currencyRates} currency={currency} handleCurrencyChange={handleCurrencyChange} /> },
            {
              path: "/store/:productId",
              element: <ProductDetails cart={cart} rates={currencyRates} currency={currency} handleClick={handleClick} showCartModal={showCartModal} setShowCartModal={setShowCartModal} />,
            }

          ]
        },
        {
          path: "account/nfts",
          element: <MyNfts />
        },
        // {
        //   path: "test",
        //   element: <Test />
        // },
        {
          path: "order-history",
          element: <OrderHistory
            walletId={walletId}
            currency={currency}
            currencyRates={currencyRates} />
        },
        {
          path: "thank-you/:transactionId",
          element: <ThankYouPage user={user} />
        },
        {
          path: "search",
          element: <Search searchData={searchData} currency={currency} />
        },
        {
          path: "checkout",
          // loader: checkoutLoader,
          element: <Checkout
            currency={currency}
            currencyRates={currencyRates}
            cart={cart}
            setCart={setCart}
            walletId={walletId}
            coupon={coupon}
            handleUserChange={handleUserChange}
            handleCouponClick={handleCouponClick}
            setShowCartModal={setShowCartModal}
            tokenIds={tokenIds}
             />
        },
        {
          path: "shopping-cart",
          element: <ShoppingCart
            currency={currency}
            cart={cart}
            setCart={setCart}
            currencyRates={currencyRates}
            handleChange={handleChange}
            coupon={coupon}
            handleCouponClick={handleCouponClick}
          />
        },
      ],

    },
    {
      path: "/api",
      element: <Root />,
    }
  ]);


  return (
    <RouterProvider router={router} />

  );


}
