import React, { useEffect, useState } from 'react';
import Slider from "react-slick";

const Carousel = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        const handleResize = () => {
            const isMobile = window.matchMedia('(max-width: 900px)').matches;
            setIsMobile(isMobile);
        };

        handleResize(); // Check initial viewport size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isMobile) {
            var settings = {
                dots: true,
                infinite: true,
                speed: 1000,
                slidesToShow: 1.5,
                slidesToScroll: 1,
                adaptiveHeight: true,
                autoplay: true,
                arrow: true,

            };
            setSettings(settings);
        } else {
            var settings = {
                dots: true,
                infinite: true,
                speed: 1000,
                slidesToShow: 2.5,
                slidesToScroll: 1,
                adaptiveHeight: true,
                autoplay: true,
                arrow: true,

            };
            setSettings(settings);
        }
    }, [isMobile]);


    return (
        <Slider {...settings} >
            {Array.from({ length: 10 }, (_, index) => (
                <div className="pe-2" key={index+1}>
                    <img style={{ height: '350px', width: '240px' }} src={'/images/nft-cards/nft_' + (index + 1) + '.png'} alt={'nokko #0' + (index + 1)} />
                </div>

            ))}


        </Slider>
    );
}

export default Carousel;
