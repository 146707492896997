// const proxyUrl = 'https://cors-anywhere.herokuapp.com/';

import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const api_key = process.env.REACT_APP_API_KEY;



export const getProducts = async (tagname: any, order: any) => {
  const response = await fetch(`${API_BASE_URL}/api/printify/products?filter=tags&tagname=${tagname}&order=${order}`,
    {
      headers: {
        'Authorization': `Bearer ${api_key}`,
      },
    });

  if (!response.ok) {
    throw new Error(`API request failed with status ${response.status}`);
  }
  //  res['data'] = await data.json();
  const res = await response.json();
  //  const x = JSON.parse(res);
  return res;

};



export const getProduct = async (product_id: any) => {

  const response = await fetch(`${API_BASE_URL}/api/printify/products/${product_id}`,
    {
      headers: {
        'Authorization': `Bearer ${api_key}`,
      },
    });

  if (!response.ok) {
    throw new Error(`API request failed with status ${response.status}`);
  }
  //  res['data'] = await data.json();
  const res = await response.json();
  //  const x = JSON.parse(res);
  return res.data;
};

export const estimateCost = async (body: any) => {
  const data = body;

  const response = await fetch(`${API_BASE_URL}/api/printify/shipping`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${api_key}`,
      //   'X-CSRF-TOKEN': csrftoken
    },
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    throw new Error(`API request failed with status ${response.status}`);
  }
  const res = await response.json();
  const x = JSON.parse(res);
  return x;

};

export const submitOrder = async (body: any) => {

  const data = body;

  const response = await fetch(`${API_BASE_URL}/api/printify/orders`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${api_key}`,
      //   'X-CSRF-TOKEN': csrftoken
    },
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    throw new Error(`API request failed with status ${response.status}`);
  }
  const res = await response.json();
  const x = JSON.parse(res);
  return x;


};

export const getTags = async () => {
  const response = await fetch(`${API_BASE_URL}/api/printify/products/tags`,
    {
      headers: {
        'Authorization': `Bearer ${api_key}`,
      },
    });

  if (!response.ok) {
    throw new Error(`API request failed with status ${response.status}`);
  }
  //  res['data'] = await data.json();
  const res = await response.json();
  //  const x = JSON.parse(res);
  return res;

};

export const searchProduct = async (keyword: any) => {
  const response = await fetch(`${API_BASE_URL}/api/search?keyword=${keyword}`,
    {
      headers: {
        'Authorization': `Bearer ${api_key}`,
      },
    });

  if (!response.ok) {
    throw new Error(`API request failed with status ${response.status}`);
  }
  //  res['data'] = await data.json();
  const res = await response.json();
  //  const x = JSON.parse(res);
  return res;

};