import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { searchNfts } from "../api/nfts";
import { NFTs } from "../api/nfts";
import getSymbolFromCurrency from 'currency-symbol-map'


const Search = ({ searchData, currency }: any) => {

    const [nftMetadata, setNftMetadata] = useState<any>([] as NFTs[] | []);
    const [products, setProducts] = useState<any[]>([]);

    const [searchType, setSearchType] = useState('nfts');

    const getNFTs = async () => {
        // setIsLoading(true);
        const res = await searchNfts(searchData.nfts).then((result: any) => {
            // console.log(result)
            setNftMetadata(result.nfts);
            setProducts(searchData.products);
            return result;
        });
        // setIsLoading(false);
    };

    useEffect(() => {


        getNFTs();

        
        
        // console.log('search page', Object.values(searchData));

        // searchData.map((item: any) => ({}));

    }, [searchData]);


    useEffect(() => {

        if(products.length > nftMetadata.length){
            setSearchType('products');
        } else {
            setSearchType('nfts');
        }

    }, [nftMetadata, products]);

    const placeholder = {
        "name": "Coming Soon",
        "description": "This NFT will be revealed soon!",
        "image": "https://ipfs-2.thirdwebcdn.com/ipfs/QmPv5fkgCDMqoSD9Zri9b4WoiE8QVBGgcwn8A3StTpRcQK/coming-soon.png"
    };

    function priceConversion(price: any) {
		// const currencyRate = rates.filter((r:any) => r.value === currency.value)
		const fromCent = parseFloat(price) / 100;
		const newPrice = fromCent * currency.rate;
		// const truncatedNumber = Math.floor(newPrice * 100) / 100;
		return newPrice.toFixed(2);
	}

    function handleButtonClick(type:any) {
        setSearchType(type);
        // console.log('type', type);
    }

    return (
        <Container>
            <div className="pt-4">
                <p style={{ fontSize:'20px' }}>SEARCH RESULTS FOR: { searchData.keyword }</p>
                <button type="button" className={`btn btn-primary type-button me-2 mb-1 ${searchType === 'nfts' ? "active" : ""}`} onClick={(e)=>handleButtonClick('nfts')}>NFTs</button>
                <button type="button" className={`btn btn-primary type-button  mb-1 ${searchType === 'products' ? "active" : ""}`} onClick={(e)=>handleButtonClick('products')}>Products</button>
            </div>
            
            <Row className="equal-height ">
                {searchType === 'nfts' && nftMetadata.length != 0 ? (
                    <>
                        {nftMetadata.map((nft: any) => (
                            <Col className="mt-4" md={6} lg={3} key={nft.id} style={{ display: nft.metadata.image ? "block" : "none" }}>
                                <Card className="h-100 nft-card shadow">
                                    <Card.Img className="card-img" variant="top" src={nft.revealed ? nft.metadata.image : placeholder["image"]} />
                                    <Card.Body>
                                        <Card.Title className="nft-tittle">
                                            {nft.revealed ? nft.metadata.name : placeholder["name"]}  <span className="float-end fw-bold nft-price">{nft.price} ETH</span>
                                        </Card.Title>
                                        <Card.Text className="nft-desc">
                                            {nft.revealed ? nft.metadata.description : placeholder["description"]}
                                        </Card.Text>

                                    </Card.Body>
                                    <Card.Footer className="text-center">
                                        {nft.minted ? (
                                            <div style={{ textAlign: "center", color: "red", fontWeight: "600" }}>
                                                <p>MINTED</p>
                                            </div>
                                        ) : null
                                        }

                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </>
                ) : (
                    <div className="pt-4" style={{ display: searchType === 'nfts' ? 'block' :'none'  }}>
                        <p>No Result Found for '{ searchData.keyword }' in NFT Collection. </p>
                    </div>
                    
                )}

                {searchType === 'products' && products.length != 0 ? (
                    <>
                        {products.map((product, index) => (

                            <div className="col-lg-3 col-md-3 col-sm-6 d-flex mt-4" key={index}>

                                <div className="card w-100 my-2 shadow-2-strong product-card">
                                    <Link to={`/store/${product.id}`} style={{ textDecoration: 'none' }}>
                                        <img src={product.images.find((image: any) => image.is_default)?.src} className="card-img-top" />
                                        <div className="card-body d-flex flex-column">
                                            <h5 className="mb-1 me-1 card-text">{product.title}</h5>
                                            <p className=" price-text">{getSymbolFromCurrency(currency.value)}{priceConversion(product.variants.find((obj: any) => Boolean(Number(obj.is_enabled))).price)}
                                            </p>

                                        </div>
                                    </Link>
                                </div>


                            </div>


                        ))}
                    </>
                ) : (
                    <div className="pt-4" style={{ display: searchType === 'products' ? 'block' :'none'  }}>
                        <p>No Result Found for '{ searchData.keyword }' in NFT Collection. </p>
                    </div>
                    
                )}




            </Row>
        </Container>
    )

};


export default Search;