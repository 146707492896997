import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import { ethers } from "ethers";

const api_base_url = process.env.REACT_APP_API_BASE_URL;
const currencies = process.env.REACT_APP_CURRENCIES;
const currencies_api_key = process.env.REACT_APP_CURRENCIES_API_KEY;
const api_key = process.env.REACT_APP_API_KEY;

export const getAddress = async (wallet_id: any) => {


    const response = await fetch(api_base_url + `/api/address/wallet/` + wallet_id,{
		headers: {
		  'Authorization': `Bearer ${api_key}`,
		}});

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    const res = await response.json();
    return res.addresses;
};

export const saveWallet = async (address: any) => {


    const data = {
        "address": address,
        "network": "ETH"
    }
    const response = await fetch(api_base_url + `/api/wallet/save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${api_key}`,
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    const res = await response.json();
    return res;
};


export const saveAddress = async (address: any, wallet_id: any) => {



    const data = {
        "firstname": address.firstname,
        "lastname": address.lastname,
        "email": address.email,
        "phone": address.phone,
        "address": address.address,
        "city": address.city,
        "country": address.country,
        "postal_code": address.postal_code,
        "wallet_id": wallet_id,
        "name": address.name,
        "region": address.region,

    }

    const response = await fetch(api_base_url + `/api/address/save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${api_key}`,
            //   'X-CSRF-TOKEN': csrftoken
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    const res = await response.json();
    return res;




};

export const editAddress = async (address: any, addressId: any) => {

    const data = {
        "firstname": address.firstname,
        "lastname": address.lastname,
        "email": address.email,
        "phone": address.phone,
        "address": address.address,
        "city": address.city,
        "country": address.country,
        "postal_code": address.postal_code,
        "name": address.name,
        "region": address.region,
        // "wallet_id": 1

    }

    const response = await fetch(api_base_url + `/api/address/update/` + addressId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${api_key}`,
            //   'X-CSRF-TOKEN': csrftoken
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    const res = await response.json();
    return res;


}


export const getCoupon = async (coupon_code: any, token_ids: any, wallet_address: any) => {

    // const token_ids = token_ids;

    const response = await fetch(api_base_url + `/api/coupon/` + coupon_code + `?token_ids=`+ token_ids +`&wallet_address=`+wallet_address, {
		headers: {
		  'Authorization': `Bearer ${api_key}`,
		}
    });

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    const res = await response.json();
    return res;


};

export const saveTransaction = async (order_data: any) => {



    const data = order_data;

    const response = await fetch(api_base_url + `/api/order/save`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${api_key}`,
            //   'X-CSRF-TOKEN': csrftoken
        },
        body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    const res = await response.json();
    return res;




};

export const getCurrencyRates = async () => {


    // const res:any =[];
    // const data = order_data;
    // const data = await fetch(`https://openexchangerates.org/api/currencies.json`);
    // const response = await fetch(`https://openexchangerates.org/api/latest.json?app_id=bc5a7dd0556440bba782338709677e6c&base=USD`);

    const response = await fetch(`https://api.fastforex.io/fetch-multi?from=USD&to=${currencies}&api_key=${currencies_api_key}`);

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    //  res['data'] = await data.json();
    const res = await response.json();
    return res;




};

export const convertToEth = async () => {

    const response = await fetch(`https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH`);

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    //  res['data'] = await data.json();
    const res = await response.json();
    return res;

};


export const checkWalletIfAllowedForMinting = async (wallet_address: any, token_ids : any) => {

    const response = await fetch(api_base_url + `/api/wallet/check_wallet_if_allowed_to_mint/` + wallet_address+`?token_ids=`+token_ids, {
		headers: {
		  'Authorization': `Bearer ${api_key}`,
		}});

    if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
    }
    const res = await response.json();
    return res;
};

