
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import "./styles/ProductDetails.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Form from 'react-bootstrap/Form';
import { Button, Modal, Breadcrumb, Container } from 'react-bootstrap';
import getSymbolFromCurrency from 'currency-symbol-map';

import { getProduct, getProducts } from './api/products'



function ProductDetails({ cart, rates, currency, handleClick, showCartModal, setShowCartModal }: any) {
	const [product, setProduct] = useState<any>({});
	const navigate = useNavigate();
	// const [options, setOptions] = useState<any>({});
	let { productId } = useParams();
	const [selectedOptions, setSelectedOptions] = useState<any>({});
	const [quantity, setQuantity] = useState(1);
	const [showModal, setShowModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [filterType, setFilterType] = useState({ value: '', label: 'Filter' });
	const [sortType, setSortType] = useState({ value: '', label: 'Sort' });

	const [relatedProducts, setRelatedProducts] = useState<any[]>([]);
	// const [selectedVariant, setSelectedVariant] = useState<any>();
	// const [currency, setCurrency] = useState<any>({
	// 	value: 'USD',
	// 	label: 'United States Dollar',
	// 	rate: 1
	// });

	const [totalPrice, setTotalPrice] = useState<number>(0);

	const handleClosCartModal = () => {
		setShowCartModal(false);
	};
	const handleCartShow = () => {
		setShowModal(false);
		setShowCartModal(true);
	};




	const calculateTotalPrice = (cartItems: any) => {
		let sum = 0;
		cartItems.forEach((product: any) => {
			sum += product.quantity * product.price;
		});
		return sum;
	}

	const totalQuantity = cart.reduce((acc: any, item: any) => acc + item.quantity, 0);

	// const item = { productCode, vendor, value };

	useEffect(() => {
		setIsLoading(true);
		const fetchProducts = async () => {
			try {
				const productsData = await getProduct(productId);
				setProduct(productsData);
				setIsLoading(false)
				// console.log('prod', productsData);


				setTotalPrice(calculateTotalPrice(cart));
			} catch (error) {
				console.error('Error fetching products:', error);
			}
		};




		fetchProducts();

	}, [productId]);

	useEffect(() => {
		setIsLoading(true);
		if (product && product.tags) {
			const fetchProductsData = async () => {
				try {
					const productsData = await getProducts(product.tags[1], sortType.value);
					// console.log('productsData', product.tags[1])

					const excludeCurent = productsData.filter((item: any) => item.id !== product.id);
					setRelatedProducts(excludeCurent.slice(0, 3));
					// console.log('setRelatedProducts', excludeCurent)

				} catch (error) {
					console.error('Error fetching products:', error);
				}
				setIsLoading(false);
			};

			fetchProductsData();
		}

	}, [product]);

	useEffect(() => {
		if (Object.keys(product).length != 0) {
			const setOption = async () => {
				const defaultVariant = await product.variants.find(
					(variant: any) => Boolean(Number(variant.is_enabled))
				);

				setSelectedOptions(defaultVariant.options);
				const enabledVariants = product.variants.filter((variant: any) => variant.enabled);

				// console.log('isEnabled', enabledVariants)
			};
			setOption();
		}


	}, [product]);

	const { variants } = product;

	const selectedVariant = variants?.find((variant: any) => {
		const options = Object.values(selectedOptions);
		return variant.options.every((option: any) => options.includes(option));
	});


	// console.log('selectedOptions1111', selectedVariant)

	const handleShowModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};





	// console.log('product', product);

	function getEnabledOptions(product: any) {

		const enabledOptions: any[] = [];
		product.options.forEach((option: any) => {
			option.values.forEach((value: any) => {
				const isEnabled = product.variants.some(
					(variant: any) => variant.options.indexOf(value.id.toString()) !== -1 && Boolean(Number(variant.is_enabled))
				);

				// console.log('[isEnabled', isEnabled);
				if (isEnabled) {
					// console.log('[enabledOptions', { value, type: option.type });
					enabledOptions.push({ value, type: option.type });
				}
			});
		});


		return enabledOptions;
	}

	const getIdsWithChangedType = (data: any) => {
		let changedTypeIds: any = {};
		// changedTypeIds = { ...changedTypeIds[0], [0]: parseInt(data[].value.id) };
		const myObject: { [key: string]: number } = { 0: data[0].value.id };
		let counter = 0;
		for (let i = 0; i < data.length - 1; i++) {

			if (data[i].type != data[i + 1].type) {
				counter++;
				// console.log('this happens'+ i)

				// setSelectedOptions({ ...myObject, [counter]: data[i].value.i });

				changedTypeIds = { ...changedTypeIds[counter], [counter]: parseInt(data[i].value.id) };
				// changedTypeIds[counter] = data[i].value.id;

				// [changedTypeIds].push(data[i].value.id);
			}
		}
		return changedTypeIds;
	}


	let optionsList: any[];

	if (Object.keys(product).length > 0) {
		// console.log('myObj is not empty');
		optionsList = getEnabledOptions(product);
		// console.log('optionssss', variants);
		// console.log('tes',  Boolean(Number("1")));

		// const defaultOption = getIdsWithChangedType(optionsList);


		// setSelectedOptions(defaultOption);

	}


	const handleOptionChange = (optionId: any, valueId: any) => {
		setSelectedOptions({ ...selectedOptions, [Number(optionId)]: valueId });
		// setSelectedOptions([...selectedOptions.slice(0, optionId), valueId, ...selectedOptions.slice(optionId + 1)]);
		// console.log('optionId', optionId);
		// console.log('valueId', valueId);

		// console.log('selectedOptions', selectedOptions);
		// console.log('selectedVariant', selectedVariant);
	};



	const addToCart = (product: any) => {
		// console.log('selectedOptions2', selectedOptions);
		// console.log('selectedVariant', product.images);
		// const selectedImage = product.images.find((image: any) => image.variant_ids.includes(selectedVariant.id.toString()));
		const selectedImage = getImageSrc(product);
		// console.log('selectedImage', selectedImage);

		const item = {
			id: product.id,
			title: product.title,
			variant_id: selectedVariant.id,
			variant_sku: selectedVariant.sku,
			variant_title: selectedVariant.title,
			quantity: quantity,
			// print_provider_id: product.print_provider_id,
			// blueprint_id: product.blueprint_id,
			price: parseFloat(selectedVariant.price) / 100,
			image_src: selectedImage

		};
		// console.log('selectedImage', selectedImage);

		// console.log('item', item);
		handleClick(item, quantity);

		setShowModal(true);

	};

	function priceConversion(price: any) {
		// const priceInt = Number(price);

		// const currencyRate = rates.filter((r:any) => r.value === currency.value)
		const fromCent = parseFloat(price) / 100;
		const newPrice = fromCent * currency.rate;

		return newPrice.toFixed(2);
	}

	function priceTotalConversion(price: any) {
		// console.log('pri', price);
		// const currencyRate = rates.filter((r:any) => r.value === currency.value)
		// const fromCent = parseFloat(price) / 100;
		const newPrice = price * currency.rate;
		// const truncatedNumber = Math.floor(newPrice * 100) / 100;
		return newPrice.toFixed(2);
	}


	//   console.log('this', product); 
	if (Object.keys(product).length != 0) {

	}

	function getImages(product: any) {
		// console.log('type', type);
		// setSortType(type)
		let images = [];
		if (product.custom_images.length != 0) {
			images = product.custom_images;
		} else {
			images = product.images;
		}

		// console.log('src', src)
		return images;

	}

	function getImageSrc(product: any) {
		// console.log('type', type);
		// setSortType(type)
		let src = '';
		if (product.custom_images.length != 0) {
			let custom_src = product.custom_images.find((image: any) => Boolean(Number(image.is_default)))?.src;
			if (custom_src) {
				src = custom_src;
			} else {
				src = product.custom_images[0].src;
			}
		} else {
			const variant = product.images.find((image: any) => image.variant_ids.includes(selectedVariant.id.toString()));
			src = variant.src;
		}

		// console.log('src', src)
		return src;

	}

	function getImageSrcs(product:any){
		// console.log('type', type);
		// setSortType(type)
		let src = '';
		if ( product.custom_images.length != 0 ) {
			let custom_src = product.custom_images.find((image: any) => Boolean(Number(image.is_default)))?.src;
			if (custom_src) {
				src = custom_src;
			} else {
				src = product.custom_images[0].src;
			}
		} else {
			src = product.images.find((image: any) => image.is_default)?.src
		}

		// console.log('src', src)
		return src;

	}

	const items = [
		{ href: "/store", name: "Shop" },
		{ href: "/store/" + product?.id, name: product?.title?.substring(0, 50) + '...' },
	];

	if (isLoading) {
		return (
			<Container className="spinner-container" style={{ backgroundColor: "#FCF9EE" }}>
				{/* Loading... */}
				<div className="spinner-border" role="status">
					{/* <span className="visually-hidden">.</span> */}
				</div>
			</Container>

		);
	}

	function handleProductChange(product_id:any) {

		navigate('/store/' + product_id);

	}



	return (
		<div className='product-wrap'>
			{Object.keys(product).length != 0 ? (
				<section className="pt-4">

					<div className="container-fluid">


						<div className="row gx-5 pt-5">
							<aside className="col-lg-6">
								<Carousel showIndicators={false} showArrows={false} showThumbs={true} autoPlay={true} >
									{getImages(product).map((image: any, index: number) => (
										<div className="border rounded-4 mb-3 d-flex justify-content-center" key={index}>
											<img className="rounded-4 fit main-image" src={image.src} />
										</div>

									))}
								</Carousel>

							</aside>
							<main className="col-lg-6">
								<div className="ps-lg-3">
									<h4 className="title text-dark">
										{product.title}
									</h4>


									<div className="mt-3 mb-4">
										<span className="h5">{getSymbolFromCurrency(currency.value)}{(selectedVariant ? priceConversion(selectedVariant.price) : (product.variants && priceConversion(product?.variants[0]?.price)))}</span>
										{/* <span className="text-muted">/per box</span> */}
									</div>
									<div dangerouslySetInnerHTML={{ __html: product.description }}></div>



									{/* <hr /> */}

									<div className="row mb-4">


										{/* {product?.options?.map((option: any, index: any) => (
											<div className="col-md-4 col-6" key={index}>
												<label className="mb-2">{option.name}</label>
												<Form.Select className="form-select border border-secondary" value={selectedOptions[index]} style={{ height: "35px" }} onChange={(e) => handleOptionChange(index, e.target.value)}>
													{optionsList.filter((opt: any) => opt.type === option.type).map((opt: any, index: any) => (
														<option key={opt.value.id} value={opt.value.id}>
															{opt.value.title}
														</option>
													))}
												</Form.Select>
											</div>

										))} */}
										<div className='row d-flex pt-3'>
											{product?.options?.map((option: any, index: any) => (
												<div className="col-md-4 col-6 flex-fill" key={index}>
													<label className="mb-2">{option.name}</label>
													<Form.Select className="form-select border border-secondary" value={selectedOptions[index]} style={{ height: "56px" }} onChange={(e) => handleOptionChange(index, e.target.value)}>
														{optionsList.filter((opt: any) => opt.type === option.type).map((opt: any, index: any) => (
															<option key={opt.value.id} value={opt.value.id}>
																{opt.value.title}
															</option>
														))}
													</Form.Select>
												</div>

											))}
										</div>



										<div className="d-flex justify-content-between pt-5">

											<div className="input-group pe-2 quantity-input" >
												<button className={`btn btn-white border border-secondary px-3 ${quantity <= 1 ? "disabled" : ""}`} type="button" id="button-addon1" data-mdb-ripple-color="dark" onClick={() => setQuantity(quantity - 1)}>
													<i className="bi bi-dash"></i>
												</button>
												<input type="text" className="form-control text-center border border-secondar" readOnly placeholder="1" aria-label="Example text with button addon" value={quantity} aria-describedby="button-addon1" onChange={(e) => setQuantity(parseInt(e.target.value))} />
												<button className="btn btn-white border border-secondary px-3" type="button" id="button-addon2" data-mdb-ripple-color="dark" onClick={() => setQuantity(quantity + 1)}>

													<i className="bi bi-plus"></i>
												</button>
											</div>
											<a href="#" className="btn btn-primary shadow-0 btn-cart flex-fill" onClick={() => addToCart(product)}>  Add to bag </a>
										</div>
									</div>
									{/* <a href="#" className="btn btn-warning shadow-0"> Buy now </a> */}

									{/* <a href="#" className="btn btn-light border border-secondary py-2 icon-hover px-3"> <i className="me-1 fa fa-heart fa-lg"></i> Save </a> */}
								</div>
							</main>
						</div>
					</div>
					{relatedProducts.length != 0 ? (
						<>
							<hr className='divider' />

							<div className='container-fluid'>
								<div className='pt-5'>
									<h5>Similar products</h5>
								</div>

								<div className='text-end'>
									<Link style={{ color: '#006F56' }} className="footer-link" to={'/store'}>See more</Link>
								</div>
								<div className="row m-0">


									{relatedProducts.map((product, index) => (

										<div className="col-lg-4 col-md-4 col-sm-6 d-flex" key={index}>

											<div className="card w-100 my-2 shadow-2-strong product-card" onClick={()=> handleProductChange(product.id)}>
												{/* <Link to={`/store/${product.id}`} style={{ textDecoration: 'none' }}> */}
												<img src={getImageSrcs(product)} className="card-img-top" />
													<div className="card-body d-flex flex-column">
														<h5 className="mb-1 me-1 card-text">{product.title}</h5>
														<p className=" price-text">{getSymbolFromCurrency(currency.value)}{priceConversion(product.variants.find((obj: any) => Boolean(Number(obj.is_enabled))).price)}
														</p>

													</div>
												{/* </Link> */}
											</div>


										</div>


									))}



								</div>
							</div>

						</>


					) : null}


					<Modal
						show={showModal}
						onHide={handleCloseModal}

					>
						<Modal.Header closeButton>
							<Modal.Title>{quantity}  ITEMS ADDED TO YOUR CART</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>SUBTOTAL <span className='text-muted'>{totalQuantity} item(s)</span> : {getSymbolFromCurrency(currency.value)}{priceTotalConversion(calculateTotalPrice(cart))}</p>
						</Modal.Body>
						<Modal.Footer className='d-flex'>
							{/* <Link to={`/shopping-cart`} className='flex-fill'> */}
							<Button className='btn btn-primary flex-grow-1' variant="secondary" onClick={handleCartShow}>
								View Cart
							</Button>
							{/* </Link> */}


							<Link className='btn btn-light border flex-grow-1' to={'/store'} onClick={handleCloseModal}>
								Continue Shopping
							</Link>

						</Modal.Footer>

					</Modal>
				</section>
			) : null}
		</div>





	);
}

export default ProductDetails;

