import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ThirdwebProvider, metamaskWallet } from "@thirdweb-dev/react";
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import { Goerli } from "@thirdweb-dev/chains";

import $ from 'jquery';
import "./styles/globals.css";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'font-awesome/css/font-awesome.min.css';
import "bootstrap/js/src/collapse.js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// import 'bootstrap/dist/js/bootstrap.bundle';



// PAGES
import App from "./App";



// This is the chain your dApp will work on.
// Change this to the chain your app is built for.
// You can also import additional chains from `@thirdweb-dev/chains` and pass them directly.
const activeChain = "goerli";

const client_id = process.env.REACT_APP_SDK_CLIENT_ID;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ThirdwebProvider 
     supportedWallets={[
      metamaskWallet()
    ]}
    clientId={client_id}
    activeChain={Goerli}
    supportedChains={[Goerli]}>

      
      <App />
    </ThirdwebProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
