import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../styles/ShoppingCart.css";
import { Form, Button, Modal } from 'react-bootstrap';
import { getCoupon } from "../../api/wallets";
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map'
import { useAddress, useWallet, useNetworkMismatch, useMetamask, useSwitchChain } from "@thirdweb-dev/react";
import { Goerli } from "@thirdweb-dev/chains";



function ShoppingCart({ currency, cart, setCart, currencyRates, handleChange, coupon, handleCouponClick }: any) {
	const isMismatched = useNetworkMismatch();
	const switchChain = useSwitchChain();
	const [couponCode, setCouponCode] = useState('');
	const [newCouponAmount, setNewCouponAmount] = useState<any>();
	const address = useAddress();
	const connected = useWallet();
	const connectWithMetamask = useMetamask();
	const [showModal, setShowModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [errorMsg, setErrorMsg] = useState();
	const [removeMsg, setRemoveMsg] = useState('');
	const [removeType, setRemoveType] = useState('');
	const [sku, setSKU] = useState();
	// const [coupon, setCoupon] = useState<any>({});

	// console.log('this coup', currency)
	// console.log('this cart', cart)

	const calculateTotalPrice = (cartItems: any) => {
		let sum = 0;
		cartItems.forEach((product: any) => {
			sum += product.quantity * product.price;
		});
		return sum;
	}



	useEffect(() => {
		// console.log('calculateCoupon', coupon)
		if (connected) {
			if (isMismatched) {
				switchChain(Goerli.chainId)
			}

			// console.log('connected')
		} else {
			// console.log('disconnect')
		}
	}, [connected, address]);

	const calculateCoupon = (amount: any) => {
		const couponAmountRate = currencyRates.find((curr: any) => curr.value === coupon.currency)
		const couponAmountConversion = (currency.rate / couponAmountRate?.rate)
		const newCouponAmount = coupon.amount * couponAmountConversion;
		// setNewCouponAmount(newCouponAmount.toFixed(2));

		// console.log('couponAmountRate', couponAmountRate)
		// console.log('current', currencyRates)

		return newCouponAmount.toFixed(2)
	}


	function priceConversion(price: any) {
		// const currencyRate = rates.filter((r:any) => r.value === currency.value)
		// const fromCent = parseFloat(price) / 100;
		const newPrice = price * currency.rate;
		// const truncatedNumber = Math.floor(newPrice * 100) / 100;
		return newPrice.toFixed(2);
	}

	const calculateTotalPriceConvertion = (cartItems: any) => {
		let sum = 0;
		cartItems.forEach((product: any) => {
			const newPrice = product.price * currency.rate;
			sum += parseFloat(newPrice.toFixed(2)) * product.quantity;
		});
		// const truncatedNumber = Math.floor(sum * 100) / 100;
		// console.log('newprice', sum)
		// const final = sum ;
		return sum.toFixed(2);
	}

	const calculateTotalDiscountPriceConvertion = () => {
		const item_total = calculateTotalPriceConvertion(cart);
		const discount = calculateCoupon(cart);
		const dis = (isNaN(parseFloat(discount)) ? 0.00 : parseFloat(discount))

		const total = parseFloat(item_total) - dis;
		const final = (isNaN(total) ? 0.00 : total);
		// console.log('final', final)
		return final;
	}

	function getCouponDiscount(e: any) {
		e.preventDefault();

		// const getDiscount = async () => {
		// 	const res = await getCoupon(couponCode);
		// 	if (!res.error) {
		// 		handleCouponClick(res.data)
		// 	} else {
		// 		setErrorMsg(res.msg);
		// 		setShowModal(true);
		// 	}
		// 	// console.log('res', res)
		// 	// 


		// };

		// getDiscount();
	}

	function editCoupon() {
		// setCoupon({});
		setRemoveType('coupon');
		setRemoveMsg('Are you wanna remove this coupon?')
		setShowRemoveModal(true)
		


	}

	const handleRemove = (variant_sku: any) => {
		setCart((cart: any) => cart.filter((item: any) => item.variant_sku !== variant_sku));
	};

	const handleShowModal = () => {
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};


	const handleCloseRemoveModal = () => {
		setShowRemoveModal(false);
	};

	const remove = () => {

		if (removeType === 'coupon') {
			handleCouponClick({})
		} else if (removeType === 'cart') {
			handleRemove(sku)
		}
		setShowRemoveModal(false);
		
	}

	const handleCartRemove = (sku: any) => {
		setRemoveType('cart');
		setRemoveMsg('Are you wanna remove this item from cart?')
		setSKU(sku);
		setShowRemoveModal(true)
		
	};

	// handleRemove(item.variant_sku)

	return (
		<section className="bg-light my-5">


			<div className="container">
				<div className="row">
					<div className="col-lg-9">
						<div className="card border shadow-0">
							<div className="m-4">
								<h4 className="card-title mb-4">Your shopping cart</h4>
								{cart.length != 0 ? (
									<>
										{cart.map((item: any, index: number) => (
											<div className="row gy-3 mb-4" key={index}>
												<div className="col-lg-5">
													<div className="me-lg-5">
														<div className="d-flex">
															<img src={item.image_src} className="border rounded me-3" style={{ width: "96px", height: "96px" }} />
															<div className="">
																<a href="#" className="nav-link">{item.title}</a>
																<p className="text-muted">{item.variant_title.split(' / ').join(', ')}</p>
															</div>
														</div>
													</div>
												</div>
												<div className="col-lg-2 col-sm-6 col-6 d-flex flex-row flex-lg-column flex-xl-row text-nowrap">
													<div className="">
														<Form.Select style={{ width: "100px" }} value={item.quantity} onChange={(e) => handleChange(item.variant_sku, e.target.value)} className="form-select me-4">
															{Array.from({ length: 10 }, (_, index) => (
																<option key={index} value={index + 1}>
																	{index + 1}
																</option>
															))}
														</Form.Select>
													</div>
													<div className="">
														<span className="h6">{getSymbolFromCurrency(currency.value)}{(parseFloat(priceConversion(item.price)) * item.quantity).toFixed(2)}</span> <br />
														<small className="text-muted text-nowrap"> {getSymbolFromCurrency(currency.value)}{priceConversion(item.price)} / per item </small>
													</div>
												</div>
												<div className="col-lg col-sm-6 d-flex justify-content-sm-center justify-content-md-start justify-content-lg-center justify-content-xl-end mb-2">
													<div className="float-md-end">
														<button className="btn btn-light border text-danger icon-hover-danger" onClick={() => handleCartRemove(item.variant_sku)}> Remove</button>
													</div>
												</div>
											</div>
										))}
									</>
								) : (
									<p>Your cart is currently empty.</p>
								)}




							</div>

						</div>
					</div>

					<div className="col-lg-3">

						{connected ? (
							<div className="card mb-3 border shadow-0">
								<div className="card-body">
									{Object.keys(coupon).length && coupon.amount != 0 ? (
										<div>
											<p>
												<i className="bi bi-ticket me-2"></i>
												Coupon
												<i className="float-end bi bi-x-lg" style={{ color: "red", cursor: "pointer" }} onClick={editCoupon}></i>
												{/* <a className="float-end">Edit</a> */}
											</p>
											<p>
												{coupon.name}
											</p>
											<p className="text-muted">
												Expiration Date:
												{' ' + moment(coupon.expiration_date).format('MM/DD/YYYY, h:mm A')}
											</p>
										</div>

									) : (

										<form>
											{/* {connected ? ( */}
											<div className="form-group">
												<label className="form-label">Have coupon?</label>
												<div className="input-group">
													<input type="text" className="form-control border" name="" placeholder="Coupon code" onChange={(e) =>
														setCouponCode(e.target.value)
													} />
													<button onClick={(e) => getCouponDiscount(e)} className="btn btn-light border">Apply</button>
												</div>
											</div>
											{/* ) : null

											} */}

										</form>
									)}
								</div>
							</div>
						) : null}

						<div className="card shadow-0 border">
							<div className="card-body">
								<div className="d-flex justify-content-between">
									<p className="mb-2">Total price:</p>
									<p className="mb-2">{getSymbolFromCurrency(currency.value)}{calculateTotalPriceConvertion(cart)}</p>
								</div>
								<div className="d-flex justify-content-between">
									<p className="mb-2">Discount:</p>
									<p className="mb-2 text-success">-{getSymbolFromCurrency(currency.value)}{(coupon.amount ? calculateCoupon(coupon.amount) : '0.00')}</p>
								</div>
								{/* <div className="d-flex justify-content-between">
                  <p className="mb-2">TAX:</p>
                  <p className="mb-2">$14.00</p>
                </div> */}
								<hr />
								<div className="d-flex justify-content-between">
									<p className="mb-2">Total price:</p>
									{calculateTotalDiscountPriceConvertion() < 0 ? (
										<p className="mb-2 fw-bold">-{getSymbolFromCurrency(currency.value)}{Math.abs(calculateTotalDiscountPriceConvertion()).toFixed(2)}</p>
									) : (
										<p className="mb-2 fw-bold">{getSymbolFromCurrency(currency.value)}{calculateTotalDiscountPriceConvertion().toFixed(2)}</p>
									)}

								</div>

								<div className="mt-3">
									{connected ? (
										<>
											{cart.length != 0 ? (
												<Link className="btn btn-light w-100 shadow-0 mb-2" to={`/checkout`}> CHECKOUT </Link>
											) : null
											}
										</>

									) : (
										<button className="btn w-100 shadow-0 mb-2 connect-button" onClick={() => connectWithMetamask({ chainId: 5 })}>Connect to Metamask</button>

									)}

									<Link className="btn btn-primary w-100 border mt-2" to={`/store`}> Back to shop </Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal
				show={showModal}
				onHide={handleCloseModal}

			>
				<Modal.Header closeButton>
					<Modal.Title>Uh oh!</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>{errorMsg}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						Close
					</Button>

				</Modal.Footer>

			</Modal>

			<Modal
				show={showRemoveModal}
				onHide={handleCloseRemoveModal}

			>
				<Modal.Header closeButton>
					{/* <Modal.Title>Uh oh!</Modal.Title> */}
				</Modal.Header>
				<Modal.Body>
					<p>{removeMsg}</p>
				</Modal.Body>
				<Modal.Footer className='row px-5'>
					<Button className='col btn-cart w-50' variant="secondary" onClick={handleCloseRemoveModal}>
						No
					</Button>
					<Button className='col btn-primary  w-50' variant="secondary" onClick={remove}>
						Yes
					</Button>



				</Modal.Footer>
			</Modal>

		</section>

	);
};


export default ShoppingCart;