import { useAddress, useContract, useOwnedNFTs, useWallet, useMetamask, ConnectWallet } from "@thirdweb-dev/react";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useEffect, useState } from "react";
import { NFTs, fetchOwnedNFTs, getOwnedNft } from "./api/nfts";
import { ThirdwebSDK } from "@thirdweb-dev/sdk/evm";
import { PaginationControl } from 'react-bootstrap-pagination-control';
import "./styles/NftList.css";


export default function MyNfts() {
	const address = useAddress();
	const connected = useWallet();
	const connectWithMetamask = useMetamask();
	const { contract: nftCollection } = useContract(
		process.env.REACT_APP_PUBLIC_NFT_COLLECTION_ADDRESS
	);

	const client_id = process.env.REACT_APP_SDK_CLIENT_ID;

	const [nftMetadata, setNftMetadata] = useState<any>([] as NFTs[] | []);
	const [isLoading, setIsLoading] = useState(false);


	// const [data, setData] = useState<IData[]>([]);
	const [currentData, setCurrentData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(12); 
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	// const currentData = nftMetadata.slice(indexOfFirstItem, indexOfLastItem);

	const sdk = new ThirdwebSDK("goerli", {
		clientId: client_id, // Use secret key if using on the server, get it from dashboard settings
	});

	const placeholder = {
		"name": "Coming Soon",
		"description": "This NFT will be revealed soon!",
		"image": "https://bafybeicczepy4q7exvwejk6p7tzb34rfw6rtyz7mhozq4qmppin2o4izwm.gateway.ipfscdn.io/notRevealed.png"
	};

	useEffect(() => {
		const getNFTs = async () => {

			setIsLoading(true);
			if (connected && address) {
				const getNftTokenId = async () => {
					const owned: any = await getOwnedNft(address);
					// console.log('ownde', owned);
					let idsArray: any;
					if (owned != undefined && owned.length != 0) {
						idsArray = owned.map((item: any) => item.metadata.id);
						const tokens = idsArray != undefined ? idsArray : '';
						const res = await fetchOwnedNFTs(address, tokens).then((result: any) => {
							// console.log('result', result)

							setNftMetadata(result.nfts);
							
							setIsLoading(false);

							return result;
						});

					} else {
						setIsLoading(false);
						setCurrentData([]);
					}

					// console.log('toke', tokenIds);



				}
				getNftTokenId();

				// const res = await fetchOwnedNFTs(activePage, address).then((result: any) => {
				// 	// console.log('result', result)

				// 	setNftMetadata(result.nfts);

				// 	return result;
				// });

				
			} else {
				setNftMetadata([]);
				setIsLoading(false);
			}



			// const tokenDetails = await nftContract?.erc721.getOwned(address);
			// setNftMetadata(tokenDetails);
			// // console.log('t', tokenDetails);
			// setIsLoading(false);
		};


		getNFTs();




	}, [currentPage, address, connected]);

	useEffect(() => {
		if (!connected) {
			setIsLoading(false);
		}

	}, [connected]);

	useEffect(() => {
		if (nftMetadata) {
			const currentData = nftMetadata.slice(indexOfFirstItem, indexOfLastItem);
			setCurrentData(currentData);
		}

	}, [nftMetadata]);

	if (isLoading) {
		return (
			<Container className="spinner-container">
				{/* Loading... */}
				<div className="spinner-border" role="status">
					{/* <span className="visually-hidden">.</span> */}
				</div>
			</Container>

		);
	}

	



	return (
		<>
			<div className="py-4 page-title text-center">
				<h2 className="ps-3">My NFT Collection</h2>
			</div>

			<Container>


				{connected ? (
					<>
						<Row className="equal-height pb-4">
							{currentData.length != 0 ? (
								<>
									{currentData.map((nft: any) => (
										<Col className="mt-3" md={3} key={nft.id} style={{ display: nft.metadata.image ? "block" : "none" }}>
											<Card className="h-100 nft-card shadow">
												<Card.Img className="card-img" variant="top" src={nft.revealed ? nft.metadata.image : placeholder["image"]} />
												<Card.Body>
													<Card.Title className="nft-tittle">

														{nft.revealed ? nft.metadata.name : placeholder["name"]}  <span className="float-end fw-bold nft-price">{nft.price} ETH</span>
														<p className="m-0 pt-1" style={{ 'fontSize': '18px' }}>Token ID: <span className="float-end">{nft.metadata ? nft.metadata.token_id : ''}</span></p>
													</Card.Title>
													<Card.Text className="nft-desc">


														{nft.revealed ? nft.metadata.description : placeholder["description"]}
													</Card.Text>

												</Card.Body>

											</Card>
										</Col>
									))}
								</>
							) : (
								<>
									<div className="pt-4">

										<p>No minted NFTs yet.</p>

									</div>

								</>

							)}


						</Row>
						<div className={`mt-3 ${nftMetadata.length > 12 ? "d-block" : "d-none"}`}>
							<PaginationControl
								page={currentPage}
								between={4}
								total={Math.ceil(nftMetadata.length / itemsPerPage)}
								limit={12}
								changePage={(page) => {
									setCurrentPage(page);
								}}
								ellipsis={1}
							/>
						</div>
					</>
				) : (
					<>
						<div className="text-center my-4">


							<p>Please connect to your metamask wallet to view your collection</p>
							{/* <button className="btn w-25 shadow-0 mb-2 connect-button" onClick={() => connectWithMetamask({ chainId: 5 })}>Connect to Metamask</button> */}
							<ConnectWallet theme="dark" className="connect-button w-100" btnTitle="Connect to Metamask" />
						</div>

					</>
				)}


			</Container>
		</>

	);

}