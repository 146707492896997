import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ConnectWallet, useMetamask, useAddress, useWallet, useNetworkMismatch, useSwitchChain, useBalance } from "@thirdweb-dev/react";
import "../styles/Navbar.css";
import { Goerli } from "@thirdweb-dev/chains";
// import { Helmet } from 'react-helmet';
import Select, { StylesConfig } from 'react-select';
import { Button, Modal, Breadcrumb, Form, InputGroup } from 'react-bootstrap';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';
import { searchProduct } from '../api/products'
import { checkIfAllowedToPurchase } from '../api/orders'
import { getOwnedNft } from "../api/nfts";



const Navbar = ({ rates, currency, cart, setCart, handleCurrencyChange, showCartModal, setShowCartModal, handleChange, handleSearch, tokenIds, handleNewToken }: any) => {
	const isMismatched = useNetworkMismatch();
	const switchChain = useSwitchChain();
	const connectWithMetamask = useMetamask();

	const navigate = useNavigate();

	// const [quantity, setQuantity] = useState(1);

	const connected = useWallet();
	const address = useAddress();
	const [quantity, setQuantity] = useState(1);

	const [showSearch, setShowSearch] = useState(false);
	const [showAccountModal, setShowAccountModal] = useState(false);
	const [showNavbar, setShowNavbar] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	// const [errorMsg, setErrorMsg] = useState();
	const [removeMsg, setRemoveMsg] = useState('');
	const [removeType, setRemoveType] = useState('');
	const [sku, setSKU] = useState();

	const [keyword, setKeyword] = useState('');

	const [isMobile, setIsMobile] = useState(false);
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const [errorMsgTitle, setErrorMsgTitle] = useState('');


	useEffect(() => {
		const handleResize = () => {
			const isMobile = window.matchMedia('(max-width: 991px)').matches;
			setIsMobile(isMobile);

		};

		handleResize(); // Check initial viewport size
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);





	const handleCheckWalletToPurchase = async () => {
		setErrorMsg('');
		// const owned: any = await getOwnedNft(address);
		// const tokenId = owned?.metadata.id;
		// const allowed = await checkIfAllowedToPurchase(tokenId);


		let allowed: any = {};
		// console.log('this happenaaas', tokenIds)
		if (tokenIds != 'empty' && tokenIds != undefined) {
			// console.log('this happens', tokenIds[0]?.metadata?.id)
			allowed = await checkIfAllowedToPurchase(tokenIds[0]?.metadata?.id, address);
		} else {
			allowed = await checkIfAllowedToPurchase(undefined, address);
		}


		if (!allowed.error) {
			// console.log('allowed', allowed);
			setShowCartModal(false);
			navigate('/checkout');
			// mintNft();
		} else {
			setErrorMsgTitle(allowed.msg_title);
			setErrorMsg(allowed.msg);
			setShowErrorModal(true);

		}
	};

	const handleOptionChange = (value: any) => {
		// console.log('value', value)
		handleCurrencyChange(value);
		// setCurrency(value);
	}
	const handleShowNavbarModal = () => {
		setShowNavbar(true);
	};
	const handleCloseNavbarModal = () => {
		setShowNavbar(false);
	};

	const handleShowCartModal = () => {
		setShowCartModal(true);
	};

	const handleCloseModal = () => {
		setShowCartModal(false);
	};
	const handleSearchShow = () => {
		setShowSearch(true);
	};


	const handleAccountShow = () => {
		setShowAccountModal(true);
	};

	const handleCloseAccountModal = () => {
		setShowAccountModal(false);
	};




	useEffect(() => {
		// console.log('isMismatched', cart)
		if (connected) {
			if (isMismatched) {

				switchChain(Goerli.chainId).catch((error) => { console.error(error.cause)})
			}

			// console.log('connected', connected)

		}
	}, [connected, address]);



	function priceConversion(price: any) {
		// const currencyRate = rates.filter((r:any) => r.value === currency.value)
		// const fromCent = parseFloat(price) / 100;
		const newPrice = price * currency.rate;
		// const truncatedNumber = Math.floor(newPrice * 100) / 100;
		return newPrice.toFixed(2);
	}
	// console.log('from nav', cart)

	const totalQuantity = cart.reduce((acc: any, item: any) => acc + item.quantity, 0);
	//   console.log('from nav', totalQuantity)

	// const { address, name } = useThirdweb();
	//  const wallet = useWallet();

	// Desired length of the shortened string
	const desiredLength = 25;
	let shortenedAddress = '';

	if (address !== undefined) {
		// Total length of the original string
		const totalLength = address.length;

		// Number of characters to remove from each side of the string
		const charsToRemove = (totalLength - desiredLength) / 2;


		shortenedAddress = address.substring(0, charsToRemove) + "..." + address.substring(totalLength - charsToRemove);

		// console.log(shortenedStr); 
	}




	const iconUrl = `https://api.dicebear.com/6.x/shapes/svg?seed=` + address;

	const calculateTotalPriceConvertion = (cartItems: any) => {
		let sum = 0;
		cartItems.forEach((product: any) => {
			const newPrice = product.price * currency.rate;
			sum += parseFloat(newPrice.toFixed(2)) * product.quantity;
		});
		// const truncatedNumber = Math.floor(sum * 100) / 100;
		// console.log('newprice', sum)
		// const final = sum ;
		return sum.toFixed(2);
	}

	const addQuantity = (item: any) => {

		const add = item.quantity + 1;
		handleChange(item.variant_sku, add)
		// return quantity;

	}

	const minusQuantity = (item: any) => {

		const minus = item.quantity - 1;
		handleChange(item.variant_sku, minus)
		// return quantity;

	}

	const handleCloseRemoveModal = () => {
		setShowRemoveModal(false);
	};

	const handleRemove = (variant_sku: any) => {
		setCart((cart: any) => cart.filter((item: any) => item.variant_sku !== variant_sku));
	};

	const remove = () => {

		if (removeType === 'cart') {
			handleRemove(sku)
		}
		setShowRemoveModal(false);

	}

	const handleCartRemove = (e: any, sku: any) => {
		e.preventDefault();
		setRemoveType('cart');
		setRemoveMsg('Are you wanna remove this item from cart?')
		setSKU(sku);
		setShowRemoveModal(true)

	};

	// const gravatarUrl = `https://www.gravatar.com/avatar/${addressHash}?d=mp&s=64`;


	const colourStyles: StylesConfig = {
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? '#006F56' : '#FCF9EE', // Customize the background color for the focused option
			color: state.isFocused ? 'white' : 'black', // Customize the text color for the focused option
		}),

	};

	const handleSearchEnter = (e: any) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			fetchSearchData(keyword);

		}


	}



	const handleSearchIconClick = () => {
		fetchSearchData(keyword);
	}


	const fetchSearchData = async (key: any) => {
		try {
			const searchData = await searchProduct(key);
			handleSearch(searchData.data, key)
			setShowSearch(false);
			navigate('/search');

			// console.log('searchData', searchData)
		} catch (error) {
			console.error('Error fetching products:', error);
		}
		// setIsLoading(false);
	};


	const handleCloseErrorModal = () => {
		setShowErrorModal(false);
	};

	return (
		<>
			<nav className="navbar main-mav navbar-expand-lg navbar-dark bg-dark">
				<div className="container-fluid">
					<NavLink className="navbar-brand" to="/">
						<img className="navbar-img" alt="" src="/images/nokko-head.png" />
					</NavLink>
					{isMobile ? (
						<>
							{/* <div className="navbar"> */}

							<ul className="navbar-nav d-flex flex-row  ms-auto">

								<li className="nav-item icon-nav">
									<Button className="icon-button" onClick={handleSearchShow}>
										<i className="bi bi-search"></i>
									</Button>
									{/* <NavLink className='nav-link' to="/">Home</NavLink> */}
								</li>

								<li className="nav-item icon-nav">
									<Button className="icon-button" onClick={handleAccountShow}>
										<i className="bi bi-person"></i>
									</Button>
									{/* <NavLink className='nav-link' to="/">Home</NavLink> */}
								</li>
								<li className="nav-item icon-nav">

									<Button className="nav-link icon-button" onClick={handleShowCartModal}>
										{/* <i className="fas fa-cart"></i> */}

										<i className="bi bi-bag-fill position-relative">
											{totalQuantity > 0 ? (
												<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
													{totalQuantity}

												</span>

											) : (
												''
											)}

										</i>

										{/* <i className="fa-regular fa-cart-shopping"></i> */}


									</Button>
								</li>
								<li className="nav-item icon-nav">
									<button className="icon-button" onClick={handleShowNavbarModal}>
										<i className="bi bi-list"></i>
									</button>
								</li>

							</ul>
							{/* </div> */}

						</>
					) : (
						<>
							<div className="collapse navbar-collapse" id="navbarSupportedContent">

								<ul className="navbar-nav d-flex  ms-auto me-3">
									<li className="nav-item">
										<NavLink className='nav-link' to="/">Home</NavLink>
									</li>
									<li className="nav-item">
										<NavLink className="nav-link" to={'/store'}>Store</NavLink>
									</li>

									<li className="nav-item border-right">
										<Select
											options={rates}
											value={currency}
											onChange={handleOptionChange}
											styles={colourStyles}
											isSearchable // Enable search functionality
										/>
									</li>
									<li className="nav-item icon-nav">
										<Button className="icon-button" onClick={handleSearchShow}>
											<i className="bi bi-search"></i>
										</Button>
										{/* <NavLink className='nav-link' to="/">Home</NavLink> */}
									</li>
									<li className="nav-item icon-nav">

										<Button className="icon-button" onClick={handleAccountShow}>
											<i className="bi bi-person"></i>
										</Button>

										{/* <NavLink className='nav-link' to="/">Home</NavLink> */}
									</li>
									<li className="nav-item icon-nav">

										<Button className="nav-link icon-button" onClick={handleShowCartModal}>
											{/* <i className="fas fa-cart"></i> */}

											<i className="bi bi-bag-fill position-relative">
												{totalQuantity > 0 ? (
													<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
														{totalQuantity}

													</span>

												) : (
													''
												)}

											</i>

											{/* <i className="fa-regular fa-cart-shopping"></i> */}


										</Button>
									</li>
								</ul>
							</div>
						</>
					)}


				</div>
			</nav>


			<Modal
				show={showCartModal}
				onHide={handleCloseModal}
				fullscreen={true}
				dialogClassName="modal-right"
				className="modal-wrap"



			>
				<Modal.Header closeButton className="modal-header">

				</Modal.Header>
				<Modal.Body className="modal-body" >

					<Modal.Title className="pt-1 modal-title">YOUR BAG</Modal.Title>
					<hr className="modal-divider" />
					<div>
						{cart.map((item: any, index: any) => (

							<div className="d-flex flex-coloumn align-items-end mb-4" key={index}>
								<div className="me-3 position-relative">
									{/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-secondary">
										1
									</span> */}
									<img src={item.image_src} style={{ height: "120px", width: "120px" }} className="img-sm rounded border" />
								</div>
								<div className="">
									{/* <a href="#" className="nav-link"> */}
									{item.title}
									{/* </a> */}
									<div className="price text-muted">{getSymbolFromCurrency(currency.value)}{priceConversion(item.price)} </div>
									<span style={{ "fontSize": "14px" }}>{item.variant_title}</span>


									<div className="input-group pe-2 pt-4" style={{ width: "170px" }}>
										<button className={`btn btn-white border border-secondary px-3 ${item.quantity <= 1 ? "disabled" : ""}`} type="button" id="button-addon1" data-mdb-ripple-color="dark" onClick={() => minusQuantity(item)}>
											<i className="bi bi-dash"></i>
										</button>
										<input type="text" className="form-control text-center border border-secondar" readOnly placeholder="1" aria-label="Example text with button addon" value={item.quantity} aria-describedby="button-addon1" />
										<button className="btn btn-white border border-secondary px-3" type="button" id="button-addon2" data-mdb-ripple-color="dark" onClick={() => addQuantity(item)}>
											{/* <i className="fas fa-plus"></i> */}
											<i className="bi bi-plus"></i>
										</button>
									</div>

								</div>
								<div className="ms-auto">
									<a style={{ color: "#006F56" }} href="" onClick={(e) => handleCartRemove(e, item.variant_sku)}>Remove</a>
								</div>
							</div>
						))}
					</div>

				</Modal.Body>
				<Modal.Footer className='modal-footer'>
					<hr className="modal-divider" />
					<div className="text-right me-4 mt-2">
						<p><span className="pe-5">TOTAL</span> {getSymbolFromCurrency(currency.value)}{calculateTotalPriceConvertion(cart)}</p>
					</div>

					{connected ? (
						<>
							{cart.length != 0 ? (

								<button className='btn btn-primary w-100 flex-fill mt-4' onClick={handleCheckWalletToPurchase}>
									CHECK OUT
								</button>
							) : null
							}
						</>

					) : (
						<>
							<ConnectWallet theme="dark" className="connect-button w-100" btnTitle="Connect to Metamask" />
							{/* <button className="btn w-100 shadow-0 mb-2 connect-button" onClick={() => connectWithMetamask({ chainId: 5 })}>Connect to Metamask</button> */}
							<p className="text-center w-100">*Connect to Metamask to proceed.</p>
						</>


					)}



				</Modal.Footer>

			</Modal>

			<Modal
				show={showAccountModal}
				onHide={handleCloseAccountModal}
				fullscreen={true}
				dialogClassName="modal-right"
				className="modal-wrap"



			>
				<Modal.Header closeButton className="modal-header">

				</Modal.Header>
				<Modal.Body className="modal-body" >

					<Modal.Title className="pt-1 modal-title">Account</Modal.Title>
					{/* <hr className="modal-divider" /> */}
					<div className="row mt-3 d-flex align-items-center ">
						{address ? (
							<div className="col-2">

								<img src={iconUrl} className="rounded-circle img-fluid" alt="" loading="lazy" />

							</div>
						) : null}
						<div className="col">
							{/* {shortenedAddress} */}
							<ConnectWallet theme="dark" className="connect-button w-100" btnTitle="Connect to Metamask" />
						</div>

					</div>
					{address ? (
						<>
							<hr className="modal-divider" />
							<ul className="account-list mt-3 p-0">

								<li>

									<NavLink className='dropdown-item ps-2' to="/account/nfts" onClick={handleCloseAccountModal}>My NFTs</NavLink>


								</li>

								<li>
									<NavLink className='dropdown-item ps-2' to="/order-history" onClick={handleCloseAccountModal}>Order History</NavLink>
								</li>
							</ul>
						</>


					) : null}



					{/* <ConnectWallet theme="dark" className="connect-button" style={{borderRadius: "0"}} /> */}

				</Modal.Body>


			</Modal>


			<Modal show={showSearch} fullscreen={true} dialogClassName="modal-top" onHide={() => setShowSearch(false)}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Modal</Modal.Title> */}
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-center align-items-center h-50">
						<Form className="search-form">
							<InputGroup className="mb-3 search-input">
								<Form.Control
									type="text"
									placeholder="Search"
									value={keyword}
									onChange={(e) => setKeyword(e.target.value)}
									onKeyDown={(e) => handleSearchEnter(e)}
									autoFocus
								/>
								<InputGroup.Text className="search-icon" onClick={handleSearchIconClick}>
									<i className="bi bi-search"></i>
								</InputGroup.Text>
							</InputGroup>


						</Form>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showNavbar}
				onHide={handleCloseNavbarModal}
				fullscreen={true}
				dialogClassName="modal-right"
				className="modal-wrap"



			>
				<Modal.Header closeButton className="modal-header">

				</Modal.Header>
				<Modal.Body className="modal-body" >


					<div>
						<ul className="navbar-nav d-flex ms-auto me-3">
							<li className="nav-item ">
								<NavLink className='nav-link w-25' to="/" onClick={handleCloseNavbarModal}>Home</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="nav-link w-25" to={'/store'} onClick={handleCloseNavbarModal}>Store</NavLink>
							</li>
						</ul>
					</div>
				</Modal.Body>

			</Modal>

			<Modal
				show={showRemoveModal}
				onHide={handleCloseRemoveModal}

			>
				<Modal.Header closeButton>
					{/* <Modal.Title>Uh oh!</Modal.Title> */}
				</Modal.Header>
				<Modal.Body>
					<p>{removeMsg}</p>
				</Modal.Body>
				<Modal.Footer className='d-flex'>

					<Button className='btn btn-primary flex-grow-1' variant="secondary" onClick={handleCloseRemoveModal}>
						No
					</Button>
					<Button className='btn btn-light border flex-grow-1' variant="secondary" onClick={remove}>
						Yes
					</Button>




				</Modal.Footer>

			</Modal>

			<Modal
				show={showErrorModal}
				onHide={handleCloseErrorModal}

			>
				<Modal.Header className="ps-3">
					<Modal.Title className="text-danger p-0">{errorMsgTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body className="ps-3">
					<p>{errorMsg}</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" className="btn-primary" onClick={handleCloseErrorModal}>
						Close
					</Button>

				</Modal.Footer>

			</Modal>


		</>

	);
};

export default Navbar;
